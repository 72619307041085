const SimpleButton = (props: any) => {
  const {
    text,
    onClick,
    className,
    disabled,
    btnIcon,
    btnIconClass,
    children,
    datatoggle,
    dataplacement,
    title,
  } = props;

  return (
    <button
      className={className}
      onClick={onClick}
      data-toggle={datatoggle}
      data-placement={dataplacement}
      disabled={disabled ? true : false}
      type="button"
      title={title}
    >
      {btnIcon && <i className={`${btnIcon} ${btnIconClass || ""}`}></i>}
      {text && <span>{text}</span>}
      {children}
    </button>
  );
};

export default SimpleButton;
