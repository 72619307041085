import TextBox from "../../../../shared/components/TextBox";
import { useEffect } from "react";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  ACTION_LABEL,
  GENDER_DROP,
  LABELS,
  PREF_COMMUNICATION_MODE_DROP,
} from "../../../../shared/constants";
import { useCustomerGetQuery } from "../../../../Features/customer/customer-slice";
import DatePickerComponent from "../../../../shared/components/DatePicker/DatePicker";
import Select from "../../../../shared/components/Select";
import CheckBox from "../../../../shared/components/CheckBox/CheckBox";
import { useStoreSearchSiteCodeQuery } from "../../../../Features/store/store-slice";
import Loader from "../../../../shared/components/Loader";

const AddCustomer = (props: any) => {
  const {
    customerId,
    editMode,
    setEditMode,
    recievedMessage,
    setRecievedMessage,
    addCustomerData,
    setAddCustomerData,
    handleOnChange,
    handleOnBlur,
    handleDateChange,
    handleReceiveMessage,
    errors,
  } = props;

  const {
    data: getCustomerData,
    isLoading: getCustomerLoading,
    isSuccess: getCustomerSuccess,
  } = useCustomerGetQuery(customerId || skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const {
    data: searchSiteCodeData,
    // isSuccess ,
    // error,
    isFetching: siteCodeFetching,
  } = useStoreSearchSiteCodeQuery("");

  useEffect(() => {
    if (customerId && getCustomerSuccess) {
      setEditMode(true);
      setAddCustomerData(getCustomerData.item);
      if (getCustomerData.item.receiveMessage === "Y") {
        setRecievedMessage(true);
      }
    }
  }, [customerId, getCustomerData, getCustomerSuccess, setAddCustomerData]);

  return (
    <>
      {!getCustomerLoading ? (
        <>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <TextBox
                  name={ACTION_LABEL.SALUTATION}
                  placeholder={LABELS.SALUTATION_TEXT}
                  id={LABELS.SALUTATION_TEXT}
                  label={LABELS.SALUTATION_TEXT}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  value={addCustomerData.salutation}
                  // errorMessage={errors.salutation || null}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <TextBox
                  name={ACTION_LABEL.FIRSTNAME}
                  placeholder={LABELS.FIRSTNAME_TEXT}
                  id={LABELS.FIRSTNAME_TEXT}
                  label={LABELS.FIRSTNAME_TEXT}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  value={addCustomerData.firstName}
                  errorMessage={errors.firstName || null}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <TextBox
                  name={ACTION_LABEL.MIDDLENAME}
                  placeholder={LABELS.MIDDLE_NAME_TEXT}
                  id={LABELS.MIDDLE_NAME_TEXT}
                  label={LABELS.MIDDLE_NAME_TEXT}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  value={addCustomerData.middleName}
                  errorMessage={errors.middleName || null}
                  required
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <TextBox
                  name={ACTION_LABEL.LASTNAME}
                  placeholder={LABELS.LASTNAME_TEXT}
                  id={LABELS.LASTNAME_TEXT}
                  label={LABELS.LASTNAME_TEXT}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  value={addCustomerData.lastName}
                  errorMessage={errors.lastName || null}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <TextBox
                  name={`${ACTION_LABEL.ADDRESS}1`}
                  placeholder={`${LABELS.ADDRESS_TEXT} 1`}
                  id={`${LABELS.ADDRESS_TEXT} 1`}
                  label={`${LABELS.ADDRESS_TEXT} 1`}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  value={addCustomerData.address1}
                  // errorMessage={errors.address1 || null}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <TextBox
                  name={`${ACTION_LABEL.ADDRESS}2`}
                  placeholder={`${LABELS.ADDRESS_TEXT} 2`}
                  id={`${LABELS.ADDRESS_TEXT} 2`}
                  label={`${LABELS.ADDRESS_TEXT} 2`}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  value={addCustomerData.address2}
                  // errorMessage={errors.address2 || null}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <TextBox
                  name={ACTION_LABEL.CITY}
                  placeholder={LABELS.CITY_TEXT}
                  id={LABELS.CITY_TEXT}
                  label={LABELS.CITY_TEXT}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  value={addCustomerData.city}
                  // errorMessage={errors.city || null}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <TextBox
                  name={ACTION_LABEL.STATE}
                  placeholder={LABELS.STATE_TEXT}
                  id={LABELS.STATE_TEXT}
                  label={LABELS.STATE_TEXT}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  value={addCustomerData.state}
                  // errorMessage={errors.state|| null}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <TextBox
                  name={ACTION_LABEL.DISTRICT}
                  placeholder={LABELS.DISTRICT_TEXT}
                  id={LABELS.DISTRICT_TEXT}
                  label={LABELS.DISTRICT_TEXT}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  value={addCustomerData.district}
                  // errorMessage={errors.district || null}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <TextBox
                  name={ACTION_LABEL.PIN}
                  placeholder={LABELS.PIN_TEXT}
                  id={LABELS.PIN_TEXT}
                  label={LABELS.PIN_TEXT}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  value={addCustomerData.pin}
                  // errorMessage={errors.pin || null}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <TextBox
                  // name={ACTION_LABEL.MOBILE_NO}
                  name={ACTION_LABEL.MOBILE}
                  placeholder={LABELS.MOBILE_NO_TEXT}
                  id={LABELS.MOBILE_NO_TEXT}
                  label={LABELS.MOBILE_NO_TEXT}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  value={addCustomerData.mobile}
                  // value={addCustomerData.mobileNo}
                  errorMessage={errors.mobile || null}
                  required
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <TextBox
                  name={ACTION_LABEL.ISD_CODE}
                  placeholder={LABELS.ISD_CODE_TEXT}
                  id={LABELS.ISD_CODE_TEXT}
                  label={LABELS.ISD_CODE_TEXT}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  value={addCustomerData.isdCode}
                  errorMessage={errors.isdCode || null}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row">
            {/* <div className="col-6">
          <div className="form-group">
            <TextBox
              name={ACTION_LABEL.EMAIL}
              placeholder={LABELS.EMAIL_TEXT}
              id={LABELS.EMAIL_TEXT}
              label={LABELS.EMAIL_TEXT}
              onChange={handleOnChange}
              value={addCustomerData.email}
              errorMessage={errors.email || null}
            />
          </div>
        </div> */}

            <div className="col-6">
              <div className="form-group">
                <Select
                  data={GENDER_DROP}
                  label={LABELS.GENDER_TEXT}
                  id={LABELS.GENDER_TEXT}
                  name={ACTION_LABEL.GENDER}
                  value={addCustomerData.gender}
                  onChange={handleOnChange}
                  errorMessage={errors.gender || null}
                  required
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <DatePickerComponent
                  label={LABELS.DOB_TEXT}
                  value={addCustomerData.dob}
                  onChange={handleDateChange}
                  placeholder={"DD/MM/YYYY"}
                  format="dd/MM/yyyy"
                  placement="bottom"
                  errorMessage={errors.dob || null}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <TextBox
                  name={ACTION_LABEL.REMARKS}
                  placeholder={LABELS.REMARKS_TEXT}
                  id={LABELS.REMARKS_TEXT}
                  label={LABELS.REMARKS_TEXT}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  value={addCustomerData.remarks}
                  // errorMessage={errors.remarks || null}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <Select
                  data={PREF_COMMUNICATION_MODE_DROP}
                  label={LABELS.PREF_COMMUNICATION_MODE_TEXT}
                  id={LABELS.PREF_COMMUNICATION_MODE_TEXT}
                  name={ACTION_LABEL.PREF_COMMUNICATION_MODE}
                  value={addCustomerData.prefCommuniCationMode}
                  onChange={handleOnChange}
                  errorMessage={errors.prefCommuniCationMode || null}
                  required
                />
              </div>
            </div>
          </div>
          {/* <div className="row">
        <div className="col-6">
          <div className="form-group">
            <TextBox
              name={ACTION_LABEL.GST_STATE_NAME}
              placeholder={LABELS.GST_STATE_NAME_TEXT}
              id={LABELS.GST_STATE_NAME_TEXT}
              label={LABELS.GST_STATE_NAME_TEXT}
              onChange={handleOnChange}
              value={addCustomerData.gstStateName}
              // errorMessage={errors.gstStateName || null}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <TextBox
              name={ACTION_LABEL.GST_IN}
              placeholder={LABELS.GST_IN_TEXT}
              id={LABELS.GST_IN_TEXT}
              label={LABELS.GST_IN_TEXT}
              onChange={handleOnChange}
              value={addCustomerData.gstin}
              // errorMessage={errors.gstin || null}
            />
          </div>
        </div>
      </div> */}
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <Select
                  data={
                    !siteCodeFetching && searchSiteCodeData
                      ? searchSiteCodeData.list
                      : []
                  }
                  label={LABELS.SITE_CODE_TEXT}
                  id={LABELS.SITE_CODE_TEXT}
                  name={ACTION_LABEL.SITE_CODE}
                  value={addCustomerData?.siteCode}
                  onChange={handleOnChange}
                  errorMessage={errors.siteCode || null}
                  required
                />
              </div>
            </div>

            <div className="col-6">
              <div className="form-group">
                <label
                  htmlFor={LABELS.RECIEVE_MESSAGE_TEXT}
                  className="d-block mb-2"
                >
                  &nbsp;
                </label>
                <CheckBox
                  label={LABELS.RECIEVE_MESSAGE_TEXT}
                  name={ACTION_LABEL.RECIEVE_MESSAGE}
                  id={ACTION_LABEL.RECIEVED_MESSAGE}
                  value={recievedMessage}
                  onChange={handleReceiveMessage}
                />
                <span className="text-error">{errors.receiveMessage}</span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default AddCustomer;
