import { LABELS, MESSAGE } from "../../../../../shared/constants";
import {
  bottomMeasurement,
  topMeasurement,
} from "../../../../../shared/constants/resource";
import { getSingleMeasurementById } from "../../../../../shared/helperFunc/getMeasurementByIds";

const ConfirmPrint = (props: any) => {
  const { itemPrintData } = props;

  let multiplePrintedData: any = [];

  if (itemPrintData?.top?.length) {
    itemPrintData.top.forEach((item: any) => {
      const matchedEl = getSingleMeasurementById(topMeasurement, item);
      const count =
        itemPrintData?.itemJson?.printCounter?.top?.[matchedEl] || 0;
      if (count > 0) {
        multiplePrintedData.push({ title: item.type, count: count });
      }
    });
  }

  if (itemPrintData?.bottom?.length) {
    itemPrintData.bottom.forEach((item: any) => {
      const matchedEl = getSingleMeasurementById(bottomMeasurement, item);
      const count =
        itemPrintData?.itemJson?.printCounter?.bottom?.[matchedEl] || 0;
      if (count > 0) {
        multiplePrintedData.push({ title: item.type, count: count });
      }
    });
  }

  if (itemPrintData?.custom) {
    Object.keys(itemPrintData.custom).forEach((item: any) => {
      const count = itemPrintData?.itemJson?.printCounter?.custom?.[item] || 0;
      if (count > 0) {
        multiplePrintedData.push({ title: item, count: count });
      }
    });
  }

  return (
    <>
      <span className="d-block text-center mb-3">{MESSAGE.PRINT_MESSAGE}</span>
      {multiplePrintedData.length > 0 && (
        <table className="table table-striped mb-2 table-bordered">
          <thead>
            <tr>
              <th>{LABELS.SUB_ASSEMBLY_ITEM_TEXT}</th>
              <th>{LABELS.PRINT_COUNT_TEXT}</th>
            </tr>
          </thead>
          <tbody>
            {multiplePrintedData.map((el: any) => {
              return (
                <tr>
                  <td>{el.title}</td>
                  <td>{el.count}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </>
  );
};

export default ConfirmPrint;
