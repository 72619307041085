import { useContext, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { Navigate } from "react-router-dom";
import Sidebar from "../BackOffice/Navbar/SidebarComponent";
import { toastError, URLS } from "../shared/constants";
import { UserType } from "../shared/constants/resource";

const ProtectedLayout = () => {
  const auth = useContext(AuthContext);
  const { authState } = auth;

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const userData = localStorage.getItem("userInfo");
    const parsedData = userData ? JSON.parse(userData) : null;

    if (!parsedData || !parsedData.token) {
      localStorage.clear();
      localStorage.removeItem("userInfo");
    }
  }, []);

  useEffect(() => {
    const userPermissions = authState?.userInfo?.permissions || [];
    const path = location.pathname;
    const usertype = authState?.userInfo?.item?.type;

    let perm: any = {
      // [URLS.DASHBOARD]: "dashboard",
      [URLS.STORE]: "store.view",
      [URLS.STORE]: "store.view",
      [URLS.USER]: "users.view",
      [URLS.ROLES]: "roles.view",
      [URLS.ROLES_ADD]: "roles.edit",
      [URLS.STORE_COORDINATOR]: "storeCoordinator.view",
      [URLS.CUSTOMER]: "customer.view",
      [URLS.USER_PROFILE]: "users.view",
      // [URLS.PRODUCT]: "product.view",
      [URLS.VENDOR]: "vendor.view",
      [URLS.INVENTORY]: "inventory.view",
      [URLS.ORDER]: "order.view",
      [URLS.REPORT]: "report.view",
    };

    const isOrderDetailPage = path.match(/^\/order-details\/\d+/);

    const requiredPermission = isOrderDetailPage
      ? perm[URLS.ORDER]
      : perm[path];

    if (path === URLS.DASHBOARD) return;

    if (
      usertype !== UserType["SUPER"] &&
      authState?.userInfo?.token &&
      !userPermissions.includes(requiredPermission)
    ) {
      toastError("Forbidden access");

      navigate("/dashboard", { replace: true });
    }
  }, [authState, location.pathname, navigate]);

  if (!authState?.userInfo?.token) {
    return <Navigate replace to={URLS.LOGIN} />;
  }

  return (
    <>
      <Sidebar />
      <Outlet />
    </>
  );
};

export default ProtectedLayout;
