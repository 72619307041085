import { ERROR_MESSAGE, REGULAR_EX } from "../constants";

export const storeCoordinatorValidation = (props: any, resetKey: any): any => {
  let formErrors: any = {};
  let formValid: any = true;

  const {
    name,
    email,
    phone,
    designation,
    type,
    address,
    state,
    city,
    password,
    // role,
    store,
  } = props;

  if (!name || !name.trim()) {
    formValid = false;
    formErrors["name"] = ERROR_MESSAGE.FULL_NAME_REQUIRE;
  } else {
    if (!REGULAR_EX.NAME_REGEX.test(name)) {
      formValid = false;
      formErrors["name"] = ERROR_MESSAGE.FULLNAME_REGEX_MESSAGE;
    }
  }

  // if (!lastName) {
  //   formValid = false;
  //   formErrors["lastName"] = ERROR_MESSAGE.FULL_NAME_REQUIRE;
  // } else {
  //   if (!lastName.match(REGULAR_EX.NAME_REGEX)) {
  //     formValid = false;
  //     formErrors["lastName"] = ERROR_MESSAGE.FULLNAME_REGEX_MESSAGE;
  //   }
  // }

  if (!email || !email.trim()) {
    formValid = false;
    formErrors["email"] = ERROR_MESSAGE.EMAIL_REQUIRE;
  } else {
    if (!REGULAR_EX.EMAIL_REGEX.test(email)) {
      formValid = false;
      formErrors["email"] = ERROR_MESSAGE.EMAIL_REGEX_MESSAGE;
    }
  }

  if (resetKey) {
    if (!password || !password.trim()) {
      formValid = false;
      formErrors["password"] = ERROR_MESSAGE.PASSWORD_REQUIRE;
    }
  }

  if (!phone || !phone.trim()) {
    formValid = false;
    formErrors["phone"] = ERROR_MESSAGE.PHONE_REQUIRE;
  } else {
    if (!REGULAR_EX.PHONE_REGEX.test(phone)) {
      formValid = false;
      formErrors["phone"] = ERROR_MESSAGE.PHONE_REGEX_MESSAGE;
    }
  }

  if (!store) {
    formValid = false;
    formErrors["store"] = ERROR_MESSAGE.STORE_REQUIRE;
  }

  //   if (!role) {
  //     formValid = false;
  //     formErrors["role"] = ERROR_MESSAGE.ROLE_REQUIRE;
  //   }

  if (!address || !address.trim()) {
    formValid = false;
    formErrors["address"] = ERROR_MESSAGE.ADDRESS_REQUIRED;
  }

  if (!designation || !designation.trim()) {
    formValid = false;
    formErrors["designation"] = ERROR_MESSAGE.DESIGNATION_REQUIRED;
  }

  if (!type) {
    formValid = false;
    formErrors["type"] = ERROR_MESSAGE.USER_TYPE_REQUIRED;
  }

  if (!state || !state.trim()) {
    formValid = false;
    formErrors["state"] = ERROR_MESSAGE.STATE_REQUIRED;
  }

  if (!city || !city.trim()) {
    formValid = false;
    formErrors["city"] = ERROR_MESSAGE.CITY_REQUIRED;
  }

  return { formValid, formErrors };
};
