// import { useState, useContext } from "react";
// import { AuthContext } from "../../../context/AuthContext";
// import { Link, useLocation } from "react-router-dom";
// import { sidebarData } from "../../../shared/constants/resource";
// import { Logo } from "../../../App/assets/img";
// import { Collapse } from "react-bootstrap";
// import { URLS } from "../../../shared/constants";

// const Sidebar = () => {
//   const authContext = useContext(AuthContext);
//   const { authState } = authContext;
//   const location = useLocation();
//   const [openId, setOpenId] = useState<any>();
//   const [selectedLink, setSelectedLink] = useState("");

//   const handleCollaps = (id: any, link: any) => {
//     if (id === openId) {
//       setOpenId(null);
//     } else {
//       setSelectedLink(link);
//       setOpenId(id);
//     }
//   };

//   const usertype = authState?.userInfo?.item?.type;

//   const filteredSidebarData = sidebarData.filter((ele: any) => {
//     if (ele.visibleFor) {
//       return ele.visibleFor.includes(usertype);
//     }
//     return true;
//   });

//   return (
//     <>
//       <div className="sidebar">
//         <Link className="navbar-brand" to={URLS.DASHBOARD}>
//           <img src={Logo} alt="lock" width={350} />
//         </Link>
//         <nav className="navbar">
//           <div className="navbar-nav">
//             {filteredSidebarData.map((ele: any, index: any) => {
//               return (
//                 <>
//                   <Link
//                     to={ele.path}
//                     onClick={() => handleCollaps(index, ele.path)}
//                     className={`nav-link ${
//                       selectedLink === ele.path && "sidebar-collapse"
//                     }`}
//                   >
//                     <i className={`icon ${ele.icon}`} />

//                     {ele.title}
//                   </Link>

//                   {openId === index && (
//                     <div>
//                       <Collapse in={openId}>
//                         <div id={index}>
//                           {ele &&
//                             ele?.subItems
//                               ?.filter((item: any) =>
//                                 item.visibleFor.includes(usertype)
//                               )
//                               ?.map((subitem: any, subIndex: any) => {
//                                 return (
//                                   <Link
//                                     className={`nav-link ${
//                                       location.pathname.includes(subitem.path)
//                                         ? "active"
//                                         : ""
//                                     }`}
//                                     to={subitem.path}
//                                   >
//                                     {subitem.title}
//                                   </Link>
//                                 );
//                               })}
//                         </div>
//                       </Collapse>
//                     </div>
//                   )}
//                 </>
//               );
//             })}
//           </div>
//         </nav>
//       </div>
//     </>
//   );
//   // <div className="sidebar">
//   //   <Link className="navbar-brand" to="/dashboard">
//   //     <img src={Logo} alt="lock" width={350} />
//   //   </Link>
//   //   <nav className="navbar">
//   //     <div className="navbar-nav">
//   //       <Link className="nav-link" to="/dashboard">
//   //         <i className="icon icon-dashboard"></i>
//   //         Dashboard
//   //       </Link>
//   //       <Link to="/store-management" className="nav-link">
//   //         <i className="icon icon-store"></i>
//   //         Store Management
//   //       </Link>
//   //       <Link className="nav-link" to="#">
//   //         <i className="icon icon-brand"></i>
//   //         Brand Management
//   //       </Link>
//   //       <Link className="nav-link" to="/product-management">
//   //         <i className="icon icon-product"></i>
//   //         Product Management
//   //       </Link>
//   //       <Link className="nav-link" to="/inventory-management">
//   //         <i className="icon icon-inventory"></i>
//   //         Inventory Management
//   //       </Link>
//   //       <Link className="nav-link" to="/vendor-management">
//   //         <i className="icon icon-inventory"></i>
//   //         Vendor Management
//   //       </Link>
//   //       <Link className="nav-link" to="#">
//   //         <i className="icon icon-oreders"></i>
//   //         Order Management
//   //       </Link>
//   //       <Link className="nav-link" to="/customer-management">
//   //         <i className="icon icon-customers"></i>
//   //         Customer Management
//   //       </Link>
//   //       <Link
//   //         className="sidebar-collapse"
//   //         to={""}
//   //         onClick={() => setOpen(!open)}
//   //         aria-controls="user_management"
//   //         aria-expanded={open}
//   //       >
//   //         <i className="icon icon-users"></i>
//   //         User Management
//   //       </Link>
//   //       <div>
//   //         <Collapse in={open}>
//   //           <div id="user_management">
//   //             <Link className="nav-link" to="/user-management/users">
//   //               Users
//   //             </Link>
//   //             <Link className="nav-link" to="/user-management/store-coordinator">
//   //               Sales Persons
//   //             </Link>
//   //             <Link className="nav-link" to="/user-management/roles">
//   //               Roles
//   //             </Link>
//   //           </div>
//   //         </Collapse>
//   //       </div>
//   //       <Link className="nav-link" to="#">
//   //         <i className="icon icon-reports"></i>
//   //         Reports
//   //       </Link>
//   //     </div>
//   //   </nav>
//   // </div>
// };

// export default Sidebar;

import { useState, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { Link, useLocation } from "react-router-dom";
import { sidebarData } from "../../../shared/constants/resource";
import { Logo } from "../../../App/assets/img";
import { Collapse } from "react-bootstrap";
import { URLS } from "../../../shared/constants";
import authProvider from "../../../shared/config/authProvider";

const Sidebar = () => {
  const authContext = useContext(AuthContext);
  const { authState } = authContext;
  const location = useLocation();
  const [openId, setOpenId] = useState<any>();
  const [selectedLink, setSelectedLink] = useState("");

  const handleCollaps = (id: any, link: any) => {
    if (id === openId) {
      setOpenId(null);
    } else {
      setSelectedLink(link);
      setOpenId(id);
    }
  };

  const usertype = authState?.userInfo?.item?.type;
  const userPermissions = authState?.userInfo?.permissions || [];

  const filteredSidebarData = sidebarData.filter((ele: any) => {
    const hasPermission = ele.permissions
      ? ele.permissions.some((perm: any) => userPermissions.includes(perm))
      : true;
    return (
      (ele.visibleFor ? ele.visibleFor.includes(usertype) : true) &&
      hasPermission
    );
  });

  return (
    <div className="sidebar">
      <Link className="navbar-brand" to={URLS.DASHBOARD}>
        <img src={Logo} alt="lock" width={350} />
      </Link>
      <nav className="navbar">
        <div className="navbar-nav">
          {filteredSidebarData.map((ele: any, index: any) => {
            return (
              authProvider.show(ele.permName, ele.type) && (
                <div key={index}>
                  <Link
                    to={ele.path}
                    onClick={() => handleCollaps(index, ele.path)}
                    className={`nav-link ${
                      selectedLink === ele.path && "sidebar-collapse"
                    }`}
                  >
                    <i className={`icon ${ele.icon}`} />
                    {ele.title}
                  </Link>

                  {openId === index && (
                    <Collapse in={openId}>
                      <div>
                        {ele.subItems
                          ?.filter((subitem: any) => {
                            const hasSubitemPermission = subitem.permissions
                              ? subitem.permissions.some((perm: any) =>
                                  userPermissions.includes(perm)
                                )
                              : true;
                            return hasSubitemPermission;
                          })
                          .map((subitem: any, subIndex: any) => {
                            return (
                              // authProvider.show(
                              //   subitem.permName,
                              //   subitem.type
                              // ) ? (
                              <Link
                                key={subIndex}
                                className={`nav-link ${
                                  location.pathname.includes(subitem.path) &&
                                  "active"
                                }`}
                                to={subitem.path}
                              >
                                {subitem.title}
                              </Link>
                              // ) : (
                              //   ""
                            );
                          })}
                      </div>
                    </Collapse>
                  )}
                </div>
              )
            );
          })}
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
