import { useState } from "react";
import { icons } from "../../constants";
// import { icons } from "../../constant";

const PasswordBox = (props: any) => {
  const {
    placeholder,
    name,
    onChange,
    label,
    errorMessage,
    className,
    id,
    value,
    required,
    disable,
    icon,
  } = props;
  const [show, setShow] = useState(false);

  const handleToggle = () => {
    setShow(!show);
  };

  return (
    <>
      {icon && <i className={`icon ${icon}`}></i>}
      {label && <label htmlFor={id}>{label}</label>}
      {required && <span className="text-error">*</span>}
      <div className="position-relative password-box">
        {!disable && (
          <div className="" onClick={handleToggle}>
            <div className="password-btn">
              <i
                className={`icon ${
                  show ? icons.ICON_EYE_FILL : icons.ICON_EYE__SLASH_FILL
                } `}
              />
            </div>
          </div>
        )}
        <input
          type={show ? "text" : "password"}
          className={className || "form-control"}
          placeholder={placeholder}
          id={id}
          name={name}
          onChange={onChange}
          value={value}
          aria-label="Right Icon"
          aria-describedby="inputpassword"
          // autoComplete="off"
          autoComplete="false"
          disabled={disable || undefined}
        />
      </div>
      <span className="text-error">{errorMessage}</span>
    </>
  );
};

export default PasswordBox;
