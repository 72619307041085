import moment from "moment";
import {
  DATE_FORMAT,
  DATE_STANDARD_FORMAT,
  SECOND_DATE_FORMAT,
} from "../constants";

export const dateConvert = (value: any) => {
  let date = moment(value).format(DATE_FORMAT.REGULAR);

  return moment(value).format(DATE_FORMAT.REGULAR);
};

export const createdDateConvert = (row: any) =>
  moment(row.original.createdAt).format("D MMM YYYY,H:m:ss a");

export const updatedDateConvert = (row: any) =>
  moment(row.original.updatedAt).format("D MMM YYYY,H:m:ss a");

export const standardDateConvert = (row: any) =>
  moment(row).format(DATE_STANDARD_FORMAT.REGULAR);

export const secondDateConvert = (row: any) =>
  moment(row).format(SECOND_DATE_FORMAT.REGULAR);
