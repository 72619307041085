import { UserType } from "../constants/resource";

let userDetail: any = {};
let permissionDetail: any = [];

const authProvider = {
  getUserData: () => {
    if (typeof window) {
      const userInfo = localStorage.getItem("userInfo") ?? "{}";
      userDetail = JSON.parse(userInfo);
    }
    return userDetail;
  },

  getPermissionData: () => {
    if (typeof window) {
      const permissions = localStorage.getItem("permissions") ?? "[]";
      permissionDetail = JSON.parse(permissions);
    }
    return permissionDetail;
  },

  isUsers: (arr: any) => {
    if (typeof window) {
      const userInfo = localStorage.getItem("userInfo") ?? "{}";
      const user = JSON.parse(userInfo);
      if (!!user && user.type) return true;

      return false;
    }
  },

  show: (moduleName: any, type: any) => {
    let notPerModuleClient = ["User", "SuperAdmin", "BackOffice"];

    const notPermitData = notPerModuleClient.includes(moduleName);

    if (typeof window) {
      const userInfo: any = localStorage.getItem("userInfo") ?? "{}";
      const currentUser: any = JSON.parse(userInfo).item;
      if (currentUser?.type === UserType["SUPER"]) return true;
      if (currentUser?.type === UserType["BO"] && notPermitData) return false;
    }

    let permissions: any = [];

    if (typeof window) {
      const permissionsData = localStorage.getItem("userInfo") ?? "[]";
      permissions = JSON.parse(permissionsData).permissions;
    }

    if (type) {
      if (permissions && permissions.length > 0) {
        if (typeof moduleName === "object") {
          let val = moduleName.filter((ele: any) =>
            permissions.includes(`${ele}.${type}`)
          );

          return val.length > 0;
        } else {
          if (
            permissions.filter((el: any) => el === `${moduleName}.${type}`)
              .length > 0
          )
            return true;
        }
      }
    } else {
      if (permissions && permissions.length > 0) {
        if (permissions.filter((el: any) => el === `${moduleName}`).length > 0)
          return true;
      }
    }
    return false;
  },

  childMenuShow: (list: any) => {
    let isShow = false;
    list?.map((ele: any) => {
      if (isShow) isShow = authProvider.show(ele.permName, "view");
      else return;
    });

    return isShow;
  },
};

export default authProvider;
