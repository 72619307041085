import Navbar from "../../Navbar/NavbarComponent/Navbar";
import { useEffect, useMemo, useState } from "react";
import Table from "../../../shared/components/Table";
import {
  ACTION_LABEL,
  BUTTON_LABEL,
  icons,
  LABELS,
  SEARCH_DATA,
} from "../../../shared/constants";
import CustomModal from "../../../shared/components/CustomModal";
import { useNavigate } from "react-router-dom";
import Search from "../../../shared/components/Search";
import PrintOrder from "./OrderDetail/PrintOrder/PrintOrder";
import PopulateBar from "./PopulateBar";
import { useOrderListQuery } from "../../../Features/order/order-slice";
import SimpleButton from "../../../shared/components/Button/Button";
import { standardDateConvert } from "../../../shared/helperFunc/dateConvert";
import { ORDER_STATUS } from "../../../shared/constants/resource";
import authProvider from "../../../shared/config/authProvider";
import Filter from "../../Common/Filter/Filter";
import { useFilter } from "../../../shared/utils/hooks/useFilter";
import { convertToAppropriateType } from "../../../shared/helperFunc/convertToAppropriateType";

const OrderList = () => {
  const navigate = useNavigate();

  const [search, setSearch] = useState(false);
  const [sort, setSort] = useState(SEARCH_DATA);
  const [count, setCount] = useState();
  const [rowData, setRowData] = useState();
  const [orderList, setOrderList]: any = useState([]);
  const [showPrintOrder, setShowPrintOrder] = useState(false);
  const [showPopulateBar, setShowPopulateBar] = useState(false);
  const [orderId, setOrderId]: any = useState();
  const [populateData, setPopulateData]: any = useState();
  const [currentPrintData, setCurrentPrintData] = useState(null);
  const [handleFilter] = useFilter(sort, setSort);
  const [showFilter, setShowFilter] = useState(false);
  const [filterCalled, setFilterCalled]: any = useState(false);
  const [filterSelectionError, setFilterSelectionError]: any = useState();
  const [filterValues, setFilterValues] = useState<any>({});

  const {
    data,
    isSuccess,
    refetch: orderRefetch,
    isFetching: orderListFetching,
  } = useOrderListQuery(sort, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  // useEffect(() => {
  //   orderRefetch();
  // }, [filterCalled]);

  useEffect(() => {
    if (isSuccess) {
      setOrderList(data.list);
      setCount(data.count);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (isSuccess && orderList && orderId) {
      let updated = orderList.find((el: any) => el?._id === orderId);
      setPopulateData(updated);
    }
  }, [orderId, orderList, isSuccess]);

  const handleCallFilter = () => {
    // if (Object.keys(sort.filter).length > 0) {
    handleFilter();
    setFilterCalled(!filterCalled);
    setSort({ ...sort, filter: filterValues, filterFlag: true, skip: 0 });
    setShowFilter(!showFilter);
    // } else {
    //   setFilterSelectionError("*Please select atlease one filter.");
    // }
  };

  const resetFilter = () => {
    setFilterValues({});
    setSort({ ...sort, filter: {}, filterFlag: false });
    setFilterCalled(!filterCalled);
    setFilterSelectionError("");
    setShowFilter(!showFilter);
  };

  const closeFilter = () => {
    setSort({ ...sort, filter: {}, filterFlag: false });
    setFilterCalled(!filterCalled);
    setFilterSelectionError("");
    setShowFilter(false);
  };

  const handleClosePrintOrder = () => {
    setShowPrintOrder(false);
  };

  // const handlePrintOrder = () => {
  //   setShowPrintOrder(true);
  // };

  const handlePrintOrder = (data: any) => {
    setCurrentPrintData(data);
    setShowPrintOrder(true);
  };

  const handleOrderDetails = (e: any, row: any) => {
    navigate(`/order-details/${row._id}`);
  };

  const closePopulateBar = () => {
    setShowPopulateBar(false);
    setTimeout(() => {
      setOrderId();
      setPopulateData();
    }, 300);
  };

  const handlePopulateBar = (row: any) => {
    setOrderId(row._id);
    setRowData(row);
    setShowPopulateBar(true);
  };

  const handleEdit = (row: any) => {};

  const handlView = (row: any) => {
    navigate(`/order-details/${row._id}`);
  };

  const handleDownload = () => {};

  const handleDelete = () => {};

  const handleSearch = () => {
    setSearch(!search);
    setSort({ ...sort, skip: 0 });
  };

  const handleSearchTextChange = (e: any) => {
    setSort({ ...sort, [e.target.name]: e.target.value, skip: 0 });
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleOnBlur = (e: any) => {
    const trimmedValue = e.target.value.trim();

    setFilterValues({
      ...filterValues,
      [e.target.name]: trimmedValue,
    });
  };

  const handleTextChange = (e: any) => {
    setFilterValues({
      ...filterValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelect = (item: any, e: any, type: string) => {
    let selectedValue = convertToAppropriateType(item?.value, type);

    if (item?.value) {
      setFilterValues({
        ...filterValues,
        [e.target.name]: selectedValue,
      });
    } else {
      let updatedFilter: any = { ...filterValues };
      delete updatedFilter[e.name];
      setFilterValues(updatedFilter);
    }
    setFilterSelectionError("");
  };

  const handleDate = (dates: any, filterName: any) => {
    const [start, end] = dates;

    const fromDate = start ? new Date(start).toISOString() : null;
    const toDate = end ? new Date(end).toISOString() : null;

    setFilterValues({
      ...filterValues,
      [filterName]: { from: fromDate, to: toDate },
    });
  };

  const filterContent = (
    handleTextChange: any,
    handleSelect: any,
    handleDate: any
  ) => {
    return [
      {
        label: LABELS.STATUS_TEXT,
        name: ACTION_LABEL.ORDER_STATUS,
        elementType: "select",
        action: handleSelect,
        type: "number",
        operator: "is from",
      },
      {
        label: LABELS.CUSTOMER_NAME_TEXT,
        name: ACTION_LABEL.CUSTOMER,
        elementType: "select",
        action: handleSelect,
        type: "",
        operator: "is from",
      },
      {
        label: LABELS.STORE_DELIVERY_DATE_TEXT,
        name: ACTION_LABEL.DELIVERY_DATE,
        elementType: "createdAt",
        action: handleDate,
        type: "",
        operator: "is from",
      },
      // {
      //   label: LABELS.DESIGN_NO_TEXT,
      //   name: ACTION_LABEL.DESIGN_NO,
      //   elementType: "text",
      //   action: handleTextChange,
      //   type: "",
      //   operator: "is from",
      // },
    ];
  };

  const columns = useMemo(
    () => [
      {
        header: LABELS.ORDER_NO_TEXT,
        accessorKey: ACTION_LABEL.DOCUMENT_NO,
        isShow: true,
      },
      {
        header: LABELS.CUSTOMER_NAME_TEXT,
        cell: (row: any) => {
          return (
            row.row.original.firstName +
            " " +
            row.row.original.middleName +
            " " +
            row.row.original.lastName
          );
        },
        isShow: true,
      },
      {
        header: LABELS.MOBILE_NO_TEXT,
        accessorKey: ACTION_LABEL.MOBILE_NO,
        isShow: true,
      },
      // {
      //   header: LABELS.DESIGN_NO_TEXT,
      //   accessorKey: ACTION_LABEL.DESIGN_NO,
      //   isShow: true,
      // },
      {
        header: LABELS.ORDER_DATE_TEXT,
        accessorKey: ACTION_LABEL.CREATED_AT,
        cell: ({ row }: any) =>
          standardDateConvert(row.original[ACTION_LABEL.CREATED_AT]),
        isShow: true,
      },
      {
        header: LABELS.STORE_DELIVERY_DATE_TEXT,
        accessorKey: ACTION_LABEL.DELIVERY_DATE,
        cell: ({ row }: any) =>
          standardDateConvert(row.original[ACTION_LABEL.DELIVERY_DATE]),
        isShow: true,
      },
      {
        header: LABELS.STATUS_TEXT,
        cell: (row: any) => {
          const orderStatus = ORDER_STATUS.find((item: any) => {
            return item._id === row.row.original.status;
          });

          return (
            orderStatus && (
              <div className={`status ${orderStatus.class}`}>
                {LABELS[orderStatus.name]}
              </div>
            )
          );
        },
        isShow: true,
      },
    ],
    [sort]
  );

  const tableAction = (
    handlView: any,
    handleDownload: any,
    handlePrintOrder: any,
    handleDelete: any,
    handlePopulateBar: any
  ) => {
    return [
      {
        elementType: "button",
        text: BUTTON_LABEL.VIEW,
        className: `${`icon ${icons.ICON_VIEW}`} mx-1 `,
        // icon: "",
        action: handlView,
        access: authProvider.show("order", "view"),
      },
      // {
      //   elementType: "button",
      //   text: BUTTON_LABEL.DOWNLOAD_PDF,
      //   className: `${`icon ${icons.ICON_FILE_PDF}`} mx-1 `,
      //   icon: `icon ${icons.ICON_VIEW}`,
      //   action: handleDownload,
      //   access: authProvider.show("order", "edit"),
      // },
      // {
      //   elementType: "button",
      //   text: BUTTON_LABEL.PRINT_TRANSACTION,
      //   className: `${`icon ${icons.ICON_PRINTER}`} mx-1 `,
      //   // icon: "",
      //   action: handlePrintOrder,
      //   access: authProvider.show("order", "edit"),
      // },
      // {
      //   elementType: "button",
      //   text: BUTTON_LABEL.DELETE,
      //   className: `${`icon ${icons.ICON_DELETE}`} mx-1 `,
      //   // icon: "",
      //   action: handleDelete,
      //   access: authProvider.show("order", "edit"),
      // },
      {
        elementType: "button",
        text: BUTTON_LABEL.POPULATE,
        className: `${`icon ${icons.ICON_POPULATE}`} mx-1 `,
        // icon: "",
        action: handlePopulateBar,
        access: authProvider.show("order", "view"),
      },
    ];
  };

  const action = tableAction(
    handlView,
    handleDownload,
    handlePrintOrder,
    handleDelete,
    handlePopulateBar
  );

  return (
    <>
      <div className="full-height">
        <Navbar title={LABELS.ORDER_MANAGEMENT_TEXT} />
        <main className="main-content">
          <div className="pt-3">
            <div className="container-fluid">
              <div className="row table-top-actions">
                <div className="col-12 col-md-12 d-flex justify-content-end">
                  <div className="d-flex align-items-center row w-100 mx-m-0">
                    <Search
                      placeholder={LABELS.SEARCH_TEXT}
                      name={ACTION_LABEL.SEARCH}
                      value={sort.q}
                      onChange={handleSearchTextChange}
                      onKeyDown={handleKeyDown}
                      onClick={handleSearch}
                    />

                    {/* {authProvider.show("order", "edit") && (
                      <SimpleButton
                        className={
                          "me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                        }
                        // onClick={handleCsvExport}
                        text={BUTTON_LABEL.EXPORT}
                      />
                    )} */}
                    <SimpleButton
                      className={
                        "me-2 btn theme-btn theme-btn-sm btn-inverse filter-btn"
                      }
                      btnIcon={icons.ICON_FILTER}
                      btnIconClass={"me-2 d-inline-block"}
                      onClick={handleCallFilter}
                    />
                  </div>
                </div>
              </div>
              <div className="card custom-card card-full mt-3">
                <div className="card-body p-0">
                  <Table
                    data={orderList}
                    columns={columns}
                    sort={sort}
                    setSort={setSort}
                    coloumnDrop={true}
                    action={action}
                    count={count}
                    isFetching={orderListFetching}
                    handleRow={handleOrderDetails}
                    isAddColSpan
                    isHandleRowEnabled
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      {/* <div className="full-height">
        <Navbar title={LABELS.ORDER_MANAGEMENT_TEXT} />

        <main className="main-content">
          <div className="pt-3">
            <div className="container-fluid">
              <div className="row table-top-actions">
                <div className="col-12 col-md-4 d-flex align-items-center">
                  <a
                    href="#"
                    className="me-2 btn theme-btn theme-btn-sm btn-inverse"
                  >
                    <i
                      className={`icon ${icons.ICON_FILTER} me-2 d-inline-block`}
                    ></i>
                    {BUTTON_LABEL.FILTER}
                  </a>
                </div>
                <div className="col-12 col-md-8 d-flex justify-content-end">
                  <div className="d-flex align-items-center row w-100 mx-m-0">
                    <div className="col-12 col-md-7 ms-auto">
                      <div className="form-group mb-0 search-input position-relative ">
                        <div className="icon">
                          <i className={`icon ${icons.ICON_SEARCH}`}></i>
                        </div>
                        <input
                          type="text"
                          className="form-control bg-white"
                          placeholder="Search"
                        />
                      </div>
                    </div>
                    <a
                      href="#"
                      className="me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                      onClick={handlePopulateBar}
                    >
                      {BUTTON_LABEL.EXPORT}
                    </a>
                  </div>
                </div>
              </div>
              <div className="card custom-card card-full mt-3">
                <div className="card-body p-0">
                  <div className="row">
                    <div className="col-12">
                      <div className="table-responsive">
                        <table className="table table-header-shadow mb-0">
                          <thead>
                            <tr>
                              <th>Order No.</th>
                              <th>Customer Name</th>
                              <th>Mobile No.</th>
                              <th>Design No.</th>
                              <th>Delivery date</th>
                              <th>Status</th>
                              <th></th>
                              <th>
                                <div className="dropdown cell-dropdown"></div>
                                <Dropdown className="dropdown cell-dropdown float-end">
                                  <Dropdown.Toggle className="no-btn">
                                    <a href="#">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18.015"
                                        viewBox="0 0 18 18.015"
                                      >
                                        <g
                                          id="Group_11176"
                                          data-name="Group 11176"
                                          transform="translate(-1297.437 -289.429)"
                                        >
                                          <g
                                            id="Rectangle_19126"
                                            data-name="Rectangle 19126"
                                            transform="translate(1297.437 289.437)"
                                            fill="#fff"
                                            stroke="#707070"
                                            stroke-width="1"
                                          >
                                            <rect
                                              width="18"
                                              height="18"
                                              stroke="none"
                                            ></rect>
                                            <rect
                                              x="0.5"
                                              y="0.5"
                                              width="17"
                                              height="17"
                                              fill="none"
                                            ></rect>
                                          </g>
                                          <line
                                            id="Line_75"
                                            data-name="Line 75"
                                            y2="18.015"
                                            transform="translate(1303.434 289.429)"
                                            fill="none"
                                            stroke="#707070"
                                            stroke-width="1"
                                          ></line>
                                          <line
                                            id="Line_76"
                                            data-name="Line 76"
                                            y2="18.015"
                                            transform="translate(1309.439 289.429)"
                                            fill="none"
                                            stroke="#707070"
                                            stroke-width="1"
                                          ></line>
                                        </g>
                                      </svg>
                                    </a>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="dropdown-menu has-checkbox">
                                    <li>
                                      <div className="dropdown-item">
                                        <div className="custom-checkbox">
                                          <input
                                            className="custom-control-input"
                                            type="checkbox"
                                            id="cat1"
                                            value="option1"
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor="cat1"
                                          >
                                            Category 1
                                          </label>
                                        </div>
                                      </div>
                                    </li>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>NMG125353</td>
                              <td>Hemanshu Mistry</td>
                              <td>987654321</td>
                              <td>125</td>
                              <td>150</td>
                              <td>
                                <div className="status text-success">
                                  Confirm
                                </div>
                              </td>
                              <td>
                                <Dropdown className="custom-dropdown d-flex align-items-center">
                                  <Dropdown.Toggle className="no-btn caret-hide">
                                    <div className="chevron-circle">
                                      <i className="icon-chevron-down"></i>
                                    </div>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item>
                                      <i className="icon-edit"></i>
                                      <span>Edit</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                      <i className="icon-file-pdf"></i>
                                      <span>Download the PDF</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                      <i className="icon-printer"></i>
                                      <span>Print Transaction</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                      <i className="icon-delete"></i>
                                      <span>Delete</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={handlePopulateBar}>
                                      <i className="icon-populate"></i>
                                      <span>Populate</span>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td>&nbsp;</td>
                            </tr>
                            <tr>
                              <td>NMG125353</td>
                              <td>Hemanshu Mistry</td>
                              <td>987654321</td>
                              <td>125</td>
                              <td>150</td>
                              <td>
                                <div className="status text-danger">
                                  Pending
                                </div>
                              </td>
                              <td>
                                <Dropdown className="custom-dropdown d-flex align-items-center">
                                  <Dropdown.Toggle className="no-btn caret-hide">
                                    <div className="chevron-circle">
                                      <i className="icon-chevron-down"></i>
                                    </div>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item>
                                      <i className="icon-edit"></i>
                                      <span>Edit</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                      <i className="icon-file-pdf"></i>
                                      <span>Download the PDF</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                      <i className="icon-printer"></i>
                                      <span>Print Transaction</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                      <i className="icon-delete"></i>
                                      <span>Delete</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                      <i className="icon-populate"></i>
                                      <span>Populate</span>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                              <td>&nbsp;</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center pagination">
                    <div className="col-12 col-md-4">
                      <div className="form-group mb-0">
                        Show
                        <select
                          name=""
                          id=""
                          className="form-control w-auto d-inline-block"
                        >
                          <option value="10">10</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        &nbsp; Entries
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group mb-0 text-center">
                        Showing 1 to 10 of 223 entries
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <nav>
                        <ul className="pagination mb-0 justify-content-end">
                          <li className="page-item">
                            <a className="page-link" href="#">
                              &lt;
                            </a>
                          </li>
                          <li className="page-item active">
                            <a className="page-link" href="#">
                              1
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              2
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              3
                            </a>
                          </li>
                          <li className="page-item">
                            <a className="page-link" href="#">
                              &gt;
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div> */}

      <PopulateBar
        show={showPopulateBar}
        orderId={orderId}
        closePopulateBar={closePopulateBar}
        handlePrintOrder={handlePrintOrder}
        populateData={populateData}
      />

      <CustomModal
        show={showPrintOrder}
        close={handleClosePrintOrder}
        size={"lg"}
        message={""}
        modalTitle={LABELS.PRINT_ORDER_TEXT}
        modalButton={undefined}
        fullscreen={undefined}
        className={""}
        centerClass={"print-view-modal"}
        modalTitleIcon={""}
      >
        <PrintOrder
          id={orderId}
          data={currentPrintData}
          currentRow={rowData}
          setShowPrintOrder={setShowPrintOrder}
          handleClosePrintOrder={handleClosePrintOrder}
          refetchOrderApi={orderRefetch}
        />
      </CustomModal>

      <Filter
        sort={filterValues}
        filterSelectionError={filterSelectionError}
        filterObject={filterContent(handleTextChange, handleSelect, handleDate)}
        show={showFilter}
        onClose={closeFilter}
        handleReset={resetFilter}
        handleApply={handleCallFilter}
        handleOnBlur={handleOnBlur}
      />
    </>
  );
};

export default OrderList;
