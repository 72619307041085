import { useEffect, useState } from "react";
import Navbar from "../../../Navbar/NavbarComponent/Navbar";
import {
  BUTTON_LABEL,
  LABELS,
  DATE_STANDARD_FORMAT,
  ACTION_LABEL,
  toastError,
  toastSuccess,
  URLS,
  icons,
} from "../../../../shared/constants";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  useOrderConfirmMutation,
  useOrderGetQuery,
  useOrderUpdateMutation,
  useOrderCancelMutation,
} from "../../../../Features/order/order-slice";
import moment from "moment";
import SimpleButton from "../../../../shared/components/Button/Button";
import Loader from "../../../../shared/components/Loader";
import Select from "../../../../shared/components/Select";
import {
  useInventoryDesignQuery,
  useInventoryFabricCodeQuery,
  useInventorySearchQuery,
  useInventorySizeColorQuery,
} from "../../../../Features/inventory/inventory-slice";
import TextBox from "../../../../shared/components/TextBox";
import authProvider from "../../../../shared/config/authProvider";
import ButtonLoader from "../../../../shared/components/ButtonLoader";
import {
  OrderStatus,
  ORDER_STATUS,
} from "../../../../shared/constants/resource";
import CustomModal from "../../../../shared/components/CustomModal";
import CancelOrder from "./CancelOrder";

const OrderDetail = (props: any) => {
  const {} = props;
  const { id } = useParams();
  const navigate = useNavigate();
  const [editOrderDetail, setEditOrderDetail] = useState(false);
  const [orderId, setOrderId]: any = useState(id);
  const [orderDetailData, setOrderDetailData]: any = useState();
  const [orderItemIndex, setOrderItemIndex] = useState();
  // const [showTopMeasurement, setShowTopMeasurement] = useState(false);
  // const [measurementTopIndex, setMeasurementTopIndex] = useState();
  // const [showBottomMeasurement, setShowBottomMeasurement] = useState(false);
  // const [measurementBottomIndex, setMeasurementBottomIndex] = useState();
  // const [showCustomMeasurement, setShowCustomMeasurement] = useState(false);
  // const [measurementCustomIndex, setMeasurementCustomIndex] = useState();

  const [orderCancelData, setOrderCancelData]: any = useState({
    remarks: "",
  });
  const [orderEditData, setOrderEditData]: any = useState({
    itemCode: orderDetailData?.itemCode,
    color: orderDetailData?.color,
    fabricCode: orderDetailData?.fabricCode,
    design: orderDetailData?.designNo,
    size: orderDetailData?.size,
    qty: orderDetailData?.qty,
  });
  const [showCancelOrder, setShowCancelOrder] = useState(false);

  let d = {
    search: orderEditData.itemCode,
    color: orderEditData?.color,
    fabricCode: orderEditData?.fabricCode,
    design: orderEditData?.design,
    size: orderEditData?.size,
  };

  const {
    data: getOrderData,
    isLoading: getOrderLoading,
    isSuccess: getOrderSuccess,
    refetch: getOrderRefetch,
  } = useOrderGetQuery(orderId || skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const searchPayload = editOrderDetail ? d : skipToken;

  const { data: inventorySearchData, isFetching: inventorySearchFetching } =
    useInventorySearchQuery(searchPayload, {
      refetchOnMountOrArgChange: true,
    });

  const { data: inventoryDesignData, isFetching: inventoryDesignFetching } =
    useInventoryDesignQuery(editOrderDetail ? {} : skipToken, {
      refetchOnMountOrArgChange: true,
    });

  const {
    data: inventoryFabricCodeData,
    isFetching: inventoryFabricCodeFetching,
  } = useInventoryFabricCodeQuery(
    orderEditData.design ? { design: orderEditData.design } : skipToken,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: inventorySizeColorData,
    isFetching: inventorySizeColorFetching,
  } = useInventorySizeColorQuery(
    orderEditData.fabricCode
      ? { fabricCode: orderEditData.fabricCode }
      : skipToken,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [orderUpdate, { isLoading: orderUpdateLoading }] =
    useOrderUpdateMutation();

  const [orderConfirm, { isLoading: orderConfirmLoading }] =
    useOrderConfirmMutation();

  const [orderCancel, { isLoading: orderCancelLoading }] =
    useOrderCancelMutation();

  useEffect(() => {
    if (orderId && getOrderSuccess) {
      setOrderDetailData(getOrderData.item);
    }
  }, [orderId, getOrderData, getOrderSuccess]);

  useEffect(() => {
    if (orderDetailData) {
      setOrderEditData({
        itemCode: orderDetailData?.itemCode,
        color: orderDetailData?.color,
        fabricCode: orderDetailData?.fabricCode,
        design: orderDetailData?.designNo,
        size: orderDetailData?.size,
        qty: orderDetailData?.qty,
      });
    }
  }, [orderDetailData]);

  // useEffect(() => {
  //   if (inventorySearchData) {
  //     const matchedItem = inventorySearchData.list.find(
  //       (el: any) => el?.itemCode === orderEditData?.itemCode
  //     );
  //     // if (matchedItem) {
  //     //   // setOrderEditData(matchedItem);
  //     //   setOrderEditData(() => ({
  //     //     ...matchedItem,
  //     //     qty: orderDetailData.qty,
  //     //   }));
  //     // }

  //     if (matchedItem) {
  //       setOrderEditData({ ...matchedItem, qty: orderDetailData.qty });
  //     }
  //   }
  // }, [inventorySearchData, orderEditData?.itemCode]);

  const orderUpdateFlow = async (data: any) => {
    toastSuccess(data.message);
    setEditOrderDetail(false);
    getOrderRefetch();
  };

  const orderConfirmFlow = async (data: any) => {
    toastSuccess(data.message);
    getOrderRefetch();
  };
  const orderCancelFlow = async (data: any) => {
    toastSuccess(data.message);
    getOrderRefetch();
    setShowCancelOrder(false);
  };

  // const toggleTopMeasurement = (index: any) => {
  //   setShowTopMeasurement(!showTopMeasurement);
  //   setMeasurementTopIndex(index);
  // };

  // const toggleBottomMeasurement = (index: any) => {
  //   setShowBottomMeasurement(!showBottomMeasurement);
  //   setMeasurementBottomIndex(index);
  // };

  // const toggleCustomMeasurement = (index: any) => {
  //   setShowCustomMeasurement(!showCustomMeasurement);
  //   setMeasurementCustomIndex(index);
  // };

  const handleCancelOrder = () => {
    setShowCancelOrder(true);
  };

  const closeCancelOrder = () => {
    setShowCancelOrder(false);
  };

  const handleTextChange = (e: any) => {
    setOrderCancelData({ ...orderCancelData, remarks: e.target.value });
  };

  const handleOnBlur = (e: any) => {
    const trimmedValue = e.target.value.trim();

    setOrderCancelData({
      ...orderCancelData,
      remarks: trimmedValue,
    });
  };

  const handleSaveCancelOrder = async (id: any) => {
    const orderCancelResponse = await orderCancel({
      id: id,
      data: orderCancelData,
    })
      .unwrap()
      .catch((err: any) => {
        toastError(err.data?.errors[0]?.msg);
      });
    if (orderCancelResponse && orderCancelResponse?.status) {
      orderCancelFlow(orderCancelResponse);
    }
  };

  const handleEditOrderDetails = () => {
    setEditOrderDetail(true);
  };

  const handleUpdateOrderDetails = async (e: any, id: any) => {
    const orderUpdateResponse = await orderUpdate({
      id: id,
      data: orderEditData,
    })
      .unwrap()
      .catch((err: any) => {
        toastError(err.data?.errors[0]?.msg);
      });
    if (orderUpdateResponse && orderUpdateResponse?.status) {
      orderUpdateFlow(orderUpdateResponse);
    }
  };

  const handleConfirmOrder = async (e: any, id: any) => {
    const orderConfirmResponse = await orderConfirm(id)
      .unwrap()
      .catch((err: any) => {
        toastError(err.data?.errors[0]?.msg);
      });
    if (orderConfirmResponse && orderConfirmResponse?.status) {
      orderConfirmFlow(orderConfirmResponse);
    }
  };

  const handleOnChange = (e: any) => {
    setOrderEditData({
      ...orderEditData,
      [e.target.name]: e.target.value,
    });
  };

  const handleBack = () => {
    navigate(URLS.ORDER);
  };

  const orderCancelModalButton = [
    {
      text: BUTTON_LABEL.SUBMIT,
      action: () => handleSaveCancelOrder(orderId),
      className: "btn btn theme-btn",
    },
    {
      text: BUTTON_LABEL.CANCEL,
      action: closeCancelOrder,
      className: "btn btn theme-btn btn-inverse",
    },
  ];

  const toggleShowOrderItem = (index: any) => {
    setOrderItemIndex(index);
  };

  const toggleHideorderItem = () => {
    setOrderItemIndex(undefined);
  };

  const orderStatusBlock = (type: any) => {
    const orderStatus = ORDER_STATUS.find((ele: any) => {
      return ele._id === type;
    });
    return orderStatus ? (
      <p className={`mb-0 ${orderStatus.class} col-md-6`}>
        {LABELS[orderStatus.name]}
      </p>
    ) : (
      "-"
    );
  };

  const measurementStandardBlock = (item: any) => {
    return (
      <div className="value">
        <label htmlFor="">{item?.title}</label>
        <span className="label-box">{item?.value}</span>
      </div>
    );
  };

  const measurementCustomBlock = (title: any, value: any) => {
    if (value) {
      return (
        <div className="col-md-4">
          <div className="slug-custom-dropdown mb-3 open">
            <div className="toggle d-flex align-items-center w-100 justify-content-between">
              <span>{title}</span>
            </div>

            <div className="custom-dropdown-menu">
              <div className="item-card">
                <div className="item-body">
                  <div className="populate-values">
                    <span>{value}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      {!getOrderLoading && orderDetailData ? (
        <>
          <div className="full-height">
            <Navbar
              title={`${LABELS.ORDER_INFORMATION_TEXT} - ${orderDetailData.documentNo}`}
              backArrow={true}
              handleBack={handleBack}
            />
            <main className="main-content">
              <div className="pt-3">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12">
                      <div
                        className={`card custom-card card-full mt-3 has-radius ${
                          !editOrderDetail && "has-input"
                        }`}
                      >
                        <div className="card-body">
                          {authProvider.show("order", "edit") && (
                            <div className="d-flex align-items-center justify-content-between mb-2">
                              <p className="font-20 section-title mb-0">
                                {LABELS.ORDER_DETAILS_TEXT}
                              </p>
                              <div>
                                {orderDetailData.status !==
                                  OrderStatus.CANCELED && (
                                  <>
                                    {orderDetailData.status ===
                                      OrderStatus.PENDING &&
                                      (editOrderDetail ? (
                                        <SimpleButton
                                          className={
                                            "btn theme-btn theme-btn-sm btn-inverse me-2"
                                          }
                                          onClick={(e: any) =>
                                            handleUpdateOrderDetails(e, orderId)
                                          }
                                          text={
                                            orderUpdateLoading ? (
                                              <ButtonLoader />
                                            ) : (
                                              BUTTON_LABEL.SAVE_ORDER
                                            )
                                          }
                                        />
                                      ) : (
                                        <SimpleButton
                                          className={
                                            "btn theme-btn theme-btn-sm me-2"
                                          }
                                          onClick={handleEditOrderDetails}
                                          text={BUTTON_LABEL.EDIT_ORDER}
                                          disabled={
                                            orderConfirmLoading && "disabled"
                                          }
                                        />
                                      ))}

                                    {(orderDetailData.status ===
                                      OrderStatus.PENDING ||
                                      orderDetailData.status ===
                                        OrderStatus.CONFIRM_IN_PROGRESS) && (
                                      <SimpleButton
                                        className={`btn ${
                                          orderDetailData.status !==
                                          OrderStatus.CONFIRM_IN_PROGRESS
                                            ? "success-btn"
                                            : "info-btn"
                                        } theme-btn theme-btn-sm btn-inverse d-inline-block me-2`}
                                        text={
                                          orderConfirmLoading ? (
                                            <ButtonLoader />
                                          ) : orderDetailData.status !==
                                            OrderStatus.CONFIRM_IN_PROGRESS ? (
                                            BUTTON_LABEL.CONFIRM_ORDER
                                          ) : (
                                            BUTTON_LABEL.CONFIRM_IN_PROGRESS
                                          )
                                        }
                                        disabled={
                                          editOrderDetail || orderConfirmLoading
                                            ? "disabled"
                                            : ""
                                        }
                                        onClick={(e: any) =>
                                          handleConfirmOrder(e, orderId)
                                        }
                                      />
                                    )}
                                    <SimpleButton
                                      className={
                                        "btn theme-btn theme-btn-sm me-2"
                                      }
                                      onClick={handleCancelOrder}
                                      text={BUTTON_LABEL.CANCEL_ORDER}
                                      disabled={
                                        orderConfirmLoading && "disabled"
                                      }
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                          )}

                          <div className="card section-card mb-3">
                            <div className="card-body">
                              <div className="row align-items-center"></div>
                              <div className="row align-items-center">
                                <div className="col-md-4">
                                  <div className="form-group row mb-3">
                                    <div className="col-md-6">
                                      <strong>{LABELS.ORDER_NO_TEXT} - </strong>
                                    </div>
                                    <div className="col-md-6">
                                      {orderDetailData.documentNo
                                        ? orderDetailData.documentNo
                                        : " -"}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group row">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor=""
                                        className="text-black font-weight-600"
                                      >
                                        {LABELS.ORDER_DATE_TEXT} -
                                      </label>
                                    </div>
                                    <div className="col-md-6">
                                      <p className="mb-0 text-muted">
                                        {moment(
                                          orderDetailData.createdAt
                                        ).format(DATE_STANDARD_FORMAT.REGULAR)}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row align-items-center">
                                <div className="col-md-4">
                                  <div className="form-group row">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor=""
                                        className="text-black font-weight-600"
                                      >
                                        {LABELS.STORE_DELIVERY_DATE_TEXT} -
                                      </label>
                                    </div>
                                    <p className="mb-0 text-muted col-md-6">
                                      {moment(
                                        orderDetailData.deliveryDate
                                      ).format(DATE_STANDARD_FORMAT.REGULAR)}
                                    </p>
                                  </div>
                                </div>
                                {/* <div className="col-md-4">
                                  <div className="form-group row">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor=""
                                        className="text-black font-weight-600"
                                      >
                                        {LABELS.CUSTOMER_DELIVERY_DATE_TEXT} -
                                      </label>
                                    </div>
                                    <p className="mb-0 text-muted col-md-6">
                                      {moment(
                                        orderDetailData.custDeliveryDate
                                      ).format(DATE_STANDARD_FORMAT.REGULAR)}
                                    </p>
                                  </div>
                                </div> */}
                                <div className="col-md-4">
                                  <div className="form-group row">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor=""
                                        className="text-black font-weight-600"
                                      >
                                        {LABELS.DESTINATION_SITE_TEXT} -
                                      </label>
                                    </div>
                                    <p className="mb-0 text-muted col-md-6">
                                      {orderDetailData.destinationSiteCode
                                        ? orderDetailData.destinationSiteCode
                                        : "-"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="row align-items-center">
                                <div className="col-md-4">
                                  <div className="form-group row">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor=""
                                        className="text-black font-weight-600"
                                      >
                                        {LABELS.SO_NO_TEXT} -
                                      </label>
                                    </div>
                                    <p className="mb-0 text-muted col-md-6">
                                      {orderDetailData.ordCode
                                        ? orderDetailData.ordCode
                                        : "-"}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group row">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor=""
                                        className="text-black font-weight-600"
                                      >
                                        {LABELS.CHECKER_ID_TEXT} -
                                      </label>
                                    </div>
                                    <p className="mb-0 text-muted col-md-6">
                                      {orderDetailData.checkerId
                                        ? orderDetailData.checkerId
                                        : "-"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="row align-items-center">
                                <div className="col-md-4">
                                  <div className="form-group row">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor=""
                                        className="text-black font-weight-600"
                                      >
                                        {LABELS.STATUS_TEXT} -
                                      </label>
                                    </div>
                                    {/* <p className="mb-0 text-muted col-md-6">
                                      {orderDetailData.checkerId
                                        ? orderDetailData.checkerId
                                        : "-"}
                                    </p> */}
                                    {orderStatusBlock(orderDetailData.status)}
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group row">
                                    <div className="col-md-6">
                                      <label
                                        htmlFor=""
                                        className="text-black font-weight-600"
                                      >
                                        {LABELS.CREATED_BY_TEXT} -
                                      </label>
                                    </div>
                                    <p className="mb-0 text-muted col-md-6">
                                      {orderDetailData.store?.name
                                        ? orderDetailData.store?.name
                                        : "-"}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              {/* ------------------------------------------------------------Conditional Sub Assembly Item Block-----------------------------------------------------------*/}

                              {/* <div className="row">
                                <p>
                                  <strong>
                                    {LABELS.SUB_ASSEMBLY_ITEM_TEXT}
                                  </strong>
                                </p>
                                {orderDetailData?.measurement?.top?.length  &&
                                  orderDetailData?.measurement?.top?.map(
                                    (topEl: any, index: any) => {
                                      return (
                                        <div className="col-md-4">
                                          <div
                                            className={`slug-custom-dropdown  ${
                                              showTopMeasurement &&
                                              measurementTopIndex === index
                                                ? "open"
                                                : ""
                                            }`}
                                            onClick={() =>
                                              toggleTopMeasurement(index)
                                            }
                                          >
                                            <div className="toggle d-flex align-items-center w-100 justify-content-between">
                                              <span>{topEl.type}</span>
                                              <span>
                                                <i
                                                  className={
                                                    showTopMeasurement &&
                                                    measurementTopIndex ===
                                                      index
                                                      ? icons.ICON_CARET_UP
                                                      : icons.ICON_CARET_DOWN
                                                  }
                                                ></i>
                                              </span>
                                            </div>
                                            {showTopMeasurement &&
                                            measurementTopIndex === index ? (
                                              <div className="custom-dropdown-menu">
                                                <div className="item-card">
                                                  <div className="item-body">
                                                    <div className="populate-values">
                                                      {topEl.data.map(
                                                        (el: any) => {
                                                          return measurementStandardBlock(
                                                            el
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                {orderDetailData?.measurement?.bottom
                                  ?.length &&
                                  orderDetailData?.measurement?.bottom?.map(
                                    (bottomEl: any, index: any) => {
                                      return (
                                        <div className="col-md-4">
                                          <div
                                            className={`slug-custom-dropdown ${
                                              showBottomMeasurement &&
                                              measurementBottomIndex === index
                                                ? "open"
                                                : ""
                                            }`}
                                            onClick={() =>
                                              toggleBottomMeasurement(index)
                                            }
                                          >
                                            <div className="toggle d-flex align-items-center w-100 justify-content-between">
                                              <span>{bottomEl.type}</span>
                                              <span>
                                                <i
                                                  className={
                                                    showBottomMeasurement &&
                                                    measurementBottomIndex ===
                                                      index
                                                      ? icons.ICON_CARET_UP
                                                      : icons.ICON_CARET_DOWN
                                                  }
                                                ></i>
                                              </span>
                                            </div>
                                            {showBottomMeasurement &&
                                            measurementBottomIndex === index ? (
                                              <div className="custom-dropdown-menu">
                                                <div className="item-card">
                                                  <div className="item-body">
                                                    <div className="populate-values">
                                                      {bottomEl.data.map(
                                                        (el: any) => {
                                                          return measurementStandardBlock(
                                                            el
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}

                                {Object.keys(
                                  orderDetailData?.measurement?.custom
                                ).map((ele: any, index: any) => {
                                  return measurementCustomBlock(
                                    ele,
                                    orderDetailData?.measurement?.custom[ele],
                                    index
                                  );
                                })}
                              </div> */}
                            </div>
                          </div>

                          <h6 className="my-3 section-title">
                            {LABELS.CUSTOMER_INFORMATION_TEXT}
                          </h6>
                          <div className="card section-card mb-3">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-3 col-12">
                                  <div className="form-group mb-0">
                                    <label
                                      htmlFor=""
                                      className="text-black font-weight-600"
                                    >
                                      {LABELS.NAME_TEXT}
                                    </label>
                                    <p className="mb-0 text-muted">
                                      {orderDetailData.firstName +
                                        " " +
                                        orderDetailData.middleName +
                                        " " +
                                        orderDetailData.lastName}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-3 col-12">
                                  <div className="form-group mb-0">
                                    <label
                                      htmlFor=""
                                      className="text-black font-weight-600"
                                    >
                                      {LABELS.PHONE_TEXT}
                                    </label>
                                    <p className="mb-0 text-muted">
                                      {orderDetailData.customerMobile}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-3 col-12">
                                  <div className="form-group mb-0">
                                    <label
                                      htmlFor=""
                                      className="text-black font-weight-600"
                                    >
                                      {LABELS.ADDRESS_TEXT}
                                    </label>
                                    <p className="mb-0 text-muted">
                                      {orderDetailData.address
                                        ? orderDetailData.address
                                        : "-"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <h6 className="my-3 section-title">
                            {LABELS.ADVANCE_PAYMENT_INFORMATION_TEXT}
                          </h6>
                          <div className="card section-card mb-3">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-3 col-12">
                                  <div className="form-group mb-0">
                                    <label
                                      htmlFor=""
                                      className="text-black font-weight-600"
                                    >
                                      {LABELS.AMOUNT_TEXT}
                                    </label>
                                    <p className="mb-0 text-muted">
                                      {orderDetailData.advancePay?.amount
                                        ? orderDetailData.advancePay?.amount
                                        : "-"}
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-3 col-12">
                                  <div className="form-group mb-0">
                                    <label
                                      htmlFor=""
                                      className="text-black font-weight-600"
                                    >
                                      {LABELS.REMARKS_TEXT}
                                    </label>
                                    <p className="mb-0 text-muted">
                                      {orderDetailData.advancePay?.remarks
                                        ? orderDetailData.advancePay?.remarks
                                        : "-"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {orderDetailData.status === OrderStatus.CANCELED && (
                            <>
                              <h6 className="my-3 section-title">
                                {LABELS.CANCEL_DETAILS_TEXT}
                              </h6>
                              <div className="card section-card mb-3">
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-md-3 col-12">
                                      <div className="form-group mb-0">
                                        <label
                                          htmlFor=""
                                          className="text-black font-weight-600"
                                        >
                                          {LABELS.REMARKS_TEXT}
                                        </label>
                                        <p className="mb-0 text-muted">
                                          {orderDetailData.cancel?.remarks ||
                                            "-"}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-md-3 col-12">
                                      <div className="form-group mb-0">
                                        <label
                                          htmlFor=""
                                          className="text-black font-weight-600"
                                        >
                                          {LABELS.CANCEL_CODE_TEXT}
                                        </label>
                                        <p className="mb-0 text-muted">
                                          {orderDetailData.cancel?.code || "-"}
                                        </p>
                                      </div>
                                    </div>
                                    {/* <div className="col-md-3 col-12">
                                  <div className="form-group mb-0">
                                    <label
                                      htmlFor=""
                                      className="text-black font-weight-600"
                                    >
                                      {LABELS>EMAIL.TEXT}
                                    </label>
                                    <p className="mb-0 text-muted">
                                      hemnashum@gmail.com
                                    </p>
                                  </div>
                                </div> */}
                                    <div className="col-md-3 col-12">
                                      <div className="form-group mb-0">
                                        <label
                                          htmlFor=""
                                          className="text-black font-weight-600"
                                        >
                                          {LABELS.CANCEL_NO_TEXT}
                                        </label>
                                        <p className="mb-0 text-muted">
                                          {orderDetailData.cancel?.no || "-"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          <h6 className="my-3 section-title">
                            {LABELS.ITEM_DETAILS_TEXT}
                          </h6>
                          {orderDetailData.items.map((el: any, index: any) => {
                            return (
                              <div className="card section-card mb-3 collapsible-card">
                                <div
                                  className="card-header d-flex align-items-center justify-content-between"
                                  onClick={
                                    orderItemIndex === index
                                      ? () => toggleHideorderItem()
                                      : () => toggleShowOrderItem(index)
                                  }
                                >
                                  <h6 className="section-title card-title mb-0">
                                    <span>
                                      {el.itemCode ? el.itemCode : " -"}
                                    </span>
                                  </h6>
                                  <div className="chevron-circle">
                                    <i
                                      className={`${
                                        orderItemIndex === index
                                          ? icons.ICON_CHEVRON_UP
                                          : icons.ICON_CHEVRON_DOWN
                                      } cursor-pointer`}
                                    ></i>
                                  </div>
                                </div>
                                <div
                                  className={`card-body ${
                                    orderItemIndex === index && "show"
                                  }`}
                                >
                                  <div className="row align-items-center">
                                    <div className="col-md-4">
                                      <div className="form-group row mb-3">
                                        <div className="col-md-6">
                                          <strong>
                                            {LABELS.I_CODE_TEXT} -{" "}
                                          </strong>
                                        </div>
                                        <div className="col-md-6">
                                          {editOrderDetail ? (
                                            <Select
                                              data={
                                                !inventorySearchFetching &&
                                                inventorySearchData
                                                  ? inventorySearchData.list
                                                  : []
                                              }
                                              id={LABELS.ITEM_CODE_TEXT}
                                              name={ACTION_LABEL.ITEM_CODE}
                                              value={orderEditData?.itemCode}
                                              onChange={handleOnChange}
                                            />
                                          ) : (
                                            <>
                                              {el.itemCode ? el.itemCode : " -"}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row align-items-center">
                                    <div className="col-md-4">
                                      <div className="form-group row">
                                        <div className="col-md-6">
                                          <label
                                            htmlFor=""
                                            className="text-black font-weight-600"
                                          >
                                            {LABELS.DESIGN_NO_TEXT} -
                                          </label>
                                        </div>
                                        <div className="col-md-6">
                                          {editOrderDetail ? (
                                            <Select
                                              data={
                                                !inventoryDesignFetching &&
                                                inventoryDesignData
                                                  ? inventoryDesignData.list
                                                  : []
                                              }
                                              id={LABELS.DESIGN_NO_TEXT}
                                              name={ACTION_LABEL.DESIGN}
                                              value={orderEditData?.design}
                                              onChange={handleOnChange}
                                              disable={
                                                editOrderDetail
                                                  ? undefined
                                                  : "disable"
                                              }
                                            />
                                          ) : (
                                            <>
                                              {el?.designNo
                                                ? el?.designNo
                                                : "-"}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group row">
                                        <div className="col-md-6">
                                          <label
                                            htmlFor=""
                                            className="text-black font-weight-600"
                                          >
                                            {LABELS.PRICE_TEXT} -
                                          </label>
                                        </div>
                                        <p className="mb-0 text-muted col-md-6">
                                          {el.price}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row align-items-center">
                                    <div className="col-md-4">
                                      <div className="form-group row">
                                        <div className="col-md-6">
                                          <label
                                            htmlFor=""
                                            className="text-black font-weight-600"
                                          >
                                            {LABELS.FABRIC_CODE_TEXT} -
                                          </label>
                                        </div>
                                        <div className="col-md-6">
                                          {editOrderDetail ? (
                                            <Select
                                              data={
                                                !inventoryFabricCodeFetching &&
                                                inventoryFabricCodeData
                                                  ? inventoryFabricCodeData.list
                                                  : []
                                              }
                                              id={LABELS.FABRIC_CODE_TEXT}
                                              name={ACTION_LABEL.FABRIC_CODE}
                                              value={
                                                editOrderDetail
                                                  ? orderEditData?.fabricCode
                                                  : el.fabricCode
                                              }
                                              onChange={handleOnChange}
                                              disable={
                                                editOrderDetail &&
                                                orderEditData.design !==
                                                  undefined
                                                  ? undefined
                                                  : "disable"
                                              }
                                            />
                                          ) : (
                                            <>
                                              {el?.fabricCode
                                                ? el?.fabricCode
                                                : " -"}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group row">
                                        <div className="col-md-6">
                                          <label
                                            htmlFor=""
                                            className="text-black font-weight-600"
                                          >
                                            {LABELS.REMARKS_TEXT} -
                                          </label>
                                        </div>
                                        <p className="mb-0 text-muted col-md-6">
                                          {el.remarks ? el.remarks : "-"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row align-items-center">
                                    <div className="col-md-4">
                                      <div className="form-group row">
                                        <div className="col-md-6">
                                          <label
                                            htmlFor=""
                                            className="text-black font-weight-600"
                                          >
                                            {LABELS.COLOR_TEXT} -
                                          </label>
                                        </div>
                                        <div className="col-md-6">
                                          {editOrderDetail ? (
                                            <Select
                                              data={
                                                !inventorySizeColorFetching &&
                                                inventorySizeColorData
                                                  ? inventorySizeColorData.item
                                                      .color
                                                  : []
                                              }
                                              id={LABELS.COLOR_TEXT}
                                              name={ACTION_LABEL.COLOR}
                                              value={
                                                editOrderDetail
                                                  ? orderEditData?.color
                                                  : el?.color
                                              }
                                              onChange={handleOnChange}
                                              disable={
                                                editOrderDetail &&
                                                orderEditData.fabricCode !==
                                                  undefined
                                                  ? undefined
                                                  : "disable"
                                              }
                                            />
                                          ) : (
                                            <>{el?.color ? el?.color : " -"}</>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group row">
                                        <div className="col-md-6">
                                          <label
                                            htmlFor=""
                                            className="text-black font-weight-600"
                                          >
                                            {LABELS.STORE_DELIVERY_DATE_TEXT} -
                                          </label>
                                        </div>
                                        <p className="mb-0 text-muted col-md-6">
                                          {el?.deliveryDate
                                            ? moment(el?.deliveryDate).format(
                                                DATE_STANDARD_FORMAT.REGULAR
                                              )
                                            : "-"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row align-items-center">
                                    <div className="col-md-4">
                                      <div className="form-group row">
                                        <div className="col-md-6">
                                          <label
                                            htmlFor=""
                                            className="text-black font-weight-600"
                                          >
                                            {LABELS.SIZE_TEXT} -
                                          </label>
                                        </div>
                                        <div className="col-md-6">
                                          {editOrderDetail ? (
                                            <Select
                                              data={
                                                !inventorySizeColorFetching &&
                                                inventorySizeColorData
                                                  ? inventorySizeColorData.item
                                                      .size
                                                  : []
                                              }
                                              id={LABELS.SIZE_TEXT}
                                              name={ACTION_LABEL.SIZE}
                                              value={
                                                editOrderDetail
                                                  ? orderEditData?.size
                                                  : el?.size
                                              }
                                              onChange={handleOnChange}
                                              disable={
                                                editOrderDetail &&
                                                orderEditData.fabricCode !==
                                                  undefined
                                                  ? undefined
                                                  : "disable"
                                              }
                                            />
                                          ) : (
                                            <>{el?.size ? el?.size : " -"}</>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group row">
                                        <div className="col-md-6">
                                          <label
                                            htmlFor=""
                                            className="text-black font-weight-600"
                                          >
                                            {LABELS.CUSTOMER_DELIVERY_DATE_TEXT}{" "}
                                            -
                                          </label>
                                        </div>
                                        <p className="mb-0 text-muted col-md-6">
                                          {el?.custDeliveryDate
                                            ? moment(
                                                el?.custDeliveryDate
                                              ).format(
                                                DATE_STANDARD_FORMAT.REGULAR
                                              )
                                            : "-"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row align-items-center">
                                    <div className="col-md-4">
                                      <div className="form-group row">
                                        <div className="col-md-6">
                                          <label
                                            htmlFor=""
                                            className="text-black font-weight-600"
                                          >
                                            {LABELS.QTY_TEXT} -
                                          </label>
                                        </div>
                                        <div className="col-md-6">
                                          <TextBox
                                            placeholder={LABELS.QTY_TEXT}
                                            name={ACTION_LABEL.QTY}
                                            value={
                                              editOrderDetail
                                                ? orderEditData?.qty
                                                : el?.qty
                                            }
                                            onChange={handleOnChange}
                                            disable={
                                              editOrderDetail ? false : true
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* ------------------------------------------------------------Open Sub Assembly Item Block-----------------------------------------------------------*/}
                                  <h6 className="my-3 section-title">
                                    {LABELS.SUB_ASSEMBLY_ITEM_TEXT}
                                  </h6>
                                  <div className="card section-card mb-3">
                                    <div className="card-body">
                                      <div className="row">
                                        {el?.measurement?.top?.length > 0 &&
                                          el?.measurement?.top?.map(
                                            (topEl: any) => {
                                              return (
                                                <div className="col-md-4">
                                                  <div className="slug-custom-dropdown mb-3 open">
                                                    <div className="toggle d-flex align-items-center w-100 justify-content-between">
                                                      <span>{topEl.type}</span>
                                                    </div>
                                                    <div className="custom-dropdown-menu">
                                                      <div className="item-card">
                                                        <div className="item-body">
                                                          <div className="populate-values">
                                                            {topEl.data.map(
                                                              (el: any) => {
                                                                return measurementStandardBlock(
                                                                  el
                                                                );
                                                              }
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                        {el?.measurement?.bottom?.length > 0 &&
                                          el?.measurement?.bottom?.map(
                                            (bottomEl: any) => {
                                              return (
                                                <div className="col-md-4">
                                                  <div className="slug-custom-dropdown mb-3 open">
                                                    <div className="toggle d-flex align-items-center w-100 justify-content-between">
                                                      <span>
                                                        {bottomEl.type}
                                                      </span>
                                                    </div>
                                                    <div className="custom-dropdown-menu">
                                                      <div className="item-card">
                                                        <div className="item-body">
                                                          <div className="populate-values">
                                                            {bottomEl.data.map(
                                                              (el: any) => {
                                                                return measurementStandardBlock(
                                                                  el
                                                                );
                                                              }
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}

                                        {Object.keys(
                                          el?.measurement?.custom
                                        ).map((ele: any) => {
                                          return measurementCustomBlock(
                                            ele,
                                            el?.measurement?.custom[ele]
                                          );
                                        })}
                                        {/* <div className="col-md-4">
                                  <div className="slug-custom-dropdown">
                                    <div className="toggle d-flex align-items-center w-100 justify-content-between">
                                      <span>Kurta </span>
                                      <span>
                                        <i className="icon-caret-down"></i>
                                      </span>
                                    </div>
                                  </div>
                                </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                          <h6 className="my-3 section-title">
                            {LABELS.TRACKING_INFORMATION_TEXT}
                          </h6>
                          {/* <div className="tracking-status">
                            <div className="item">
                              <div className="item-title">Shervani</div>
                            </div>
                            <div className="track-stepper">
                              <ul className="list-unstyled mb-0">
                                <li>
                                  <div className="d-flex w-max-content justify-content-center flex-wrap">
                                    <div className="track-status done">
                                      <i className="icon-check"></i>
                                    </div>
                                    <div className="w-100 text-center">
                                      Cutting
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="d-flex w-max-content justify-content-center flex-wrap">
                                    <div className="track-status in-progress">
                                      <i className="icon-refresh"></i>
                                    </div>
                                    <div className="w-100 text-center">
                                      Embrooidry
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="d-flex w-max-content justify-content-center flex-wrap">
                                    <div className="track-status"></div>
                                    <div className="w-100 text-center">
                                      Stitching
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="d-flex w-max-content justify-content-center flex-wrap">
                                    <div className="track-status"></div>
                                    <div className="w-100 text-center">
                                      Finishing
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className="slug-custom-dropdown mt-4 d-inline-block">
                              <div className="toggle d-flex align-items-center w-100 justify-content-between primary-bg text-white">
                                <span>Sub Assembly item</span>
                                <span className="ms-3">
                                  <i className="icon-caret-down"></i>
                                </span>
                              </div>
                            </div>
                            <div className="row mt-4 align-items-center assembally-attr">
                              <div className="col-md-3">
                                <label htmlFor="">Pant</label>
                              </div>
                              <div className="col-md-6">
                                <div className="track-stepper">
                                  <ul className="list-unstyled my-0">
                                    <li>
                                      <div className="d-flex w-max-content justify-content-center flex-wrap">
                                        <div className="track-status done">
                                          <i className="icon-check"></i>
                                        </div>
                                        <div className="w-100 text-center">
                                          Cutting
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="d-flex w-max-content justify-content-center flex-wrap">
                                        <div className="track-status in-progress">
                                          <i className="icon-refresh"></i>
                                        </div>
                                        <div className="w-100 text-center">
                                          Embrooidry
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="d-flex w-max-content justify-content-center flex-wrap">
                                        <div className="track-status">
                                          <i className="icon"></i>
                                        </div>
                                        <div className="w-100 text-center">
                                          Stitching
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="d-flex w-max-content justify-content-center flex-wrap">
                                        <div className="track-status">
                                          <i className="icon"></i>
                                        </div>
                                        <div className="w-100 text-center">
                                          Finishing
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-4 align-items-center assembally-attr">
                              <div className="col-md-3">
                                <label htmlFor="">Pant</label>
                              </div>
                              <div className="col-md-6">
                                <div className="track-stepper">
                                  <ul className="list-unstyled my-0">
                                    <li>
                                      <div className="d-flex w-max-content justify-content-center flex-wrap">
                                        <div className="track-status done">
                                          <i className="icon-check"></i>
                                        </div>
                                        <div className="w-100 text-center">
                                          Cutting
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="d-flex w-max-content justify-content-center flex-wrap">
                                        <div className="track-status in-progress">
                                          <i className="icon-refresh"></i>
                                        </div>
                                        <div className="w-100 text-center">
                                          Embrooidry
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="d-flex w-max-content justify-content-center flex-wrap">
                                        <div className="track-status">
                                          <i className="icon"></i>
                                        </div>
                                        <div className="w-100 text-center">
                                          Stitching
                                        </div>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="d-flex w-max-content justify-content-center flex-wrap">
                                        <div className="track-status">
                                          <i className="icon"></i>
                                        </div>
                                        <div className="w-100 text-center">
                                          Finishing
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div> */}
                          {LABELS.NOT_FOUND_TEXT}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </>
      ) : (
        <Loader />
      )}

      <CustomModal
        show={showCancelOrder}
        close={closeCancelOrder}
        size={"lg"}
        message={""}
        modalTitle={LABELS.ITEM_DETAILS_TEXT}
        modalButton={orderCancelModalButton}
        fullscreen={undefined}
        className={""}
        centerClass={""}
        modalTitleIcon={""}
      >
        <CancelOrder
          orderId={orderId}
          orderCancelData={orderCancelData}
          orderCancelLoading={orderCancelLoading}
          handleTextChange={handleTextChange}
          handleBlur={handleOnBlur}
          handleSave={handleSaveCancelOrder}
          handleCancel={closeCancelOrder}
        />
      </CustomModal>
    </>
  );
};

export default OrderDetail;
