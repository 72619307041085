import { API_URL } from "../../shared/constants";
import { emptySplitApi } from "../emptySplit-api";

export const reportApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    reportList: builder.query<any, any>({
      query: (data: any) => {
        return {
          url: `/${API_URL.REPORTS}/list`,
          method: "post",
          body: data,
        };
      },
    }),
    reportGet: builder.query({
      query: (id) => ({
        url: `/${API_URL.REPORTS}/get/${id}`,
        method: "post",
      }),
    }),
    reportImport: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: `/${API_URL.REPORTS}/import`,
          method: "post",
          body: data,
        };
      },
    }),
    reportFollowUp: builder.query({
      query: (data: any) => ({
        url: `/${API_URL.REPORTS}/follow-up-details`,
        method: "post",
        body: data,
        refetchWindowFocus: true,
        refetchOnMountOrArgChange: true,
        keepUnusedDataFor: 10,
        staleTime: 0,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useReportListQuery,
  useReportGetQuery,
  useReportImportMutation,
  useReportFollowUpQuery,
} = reportApi;
