import { ERROR_MESSAGE } from "../constants";

export const userNameValidation = (username: any): any => {
  let formValid: any = true;
  let formError: any = {};

  if (!username || !username.trim()) {
    formValid = false;
    formError["username"] = ERROR_MESSAGE.NAME_REQUIRED;
  }

  return { formValid, formError };
};

export const otpValidation = (otp: any): any => {
  let formValid: any = true;
  let formError: any = {};

  if (!otp) {
    formValid = false;
    formError["otp"] = ERROR_MESSAGE.OTP_REQUIRED;
  }

  return { formValid, formError };
};

export const savePasswordValidation = (data: any): any => {
  let formValid: any = true;
  let formError: any = {};

  const { password } = data;

  if (!password || !password.trim()) {
    formValid = false;
    formError["password"] = ERROR_MESSAGE.PASSWORD_REQUIRE;
  }

  return { formValid, formError };
};
