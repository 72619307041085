export const getMeasurementIds = (
  measurements: any,
  measurementArray: { _id: string; name: string }[]
): string[] => {
  return measurements
    .map((measurement: any) => {
      const matched = measurementArray.find(
        (item) => item.name === measurement?.toLowerCase()
      );
      return matched?._id;
    })
    .filter((id: any) => id);
};

export const getSingleMeasurementById = (
  measurementArray: { _id: string; name: string }[],
  el: any
): string => {
  const matched = measurementArray.find(
    (item) => item.name === el?.type?.toLowerCase()
  );
  return matched?._id || "";
};
