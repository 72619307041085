import Navbar from "../../Navbar/NavbarComponent";
import CustomModal from "../../../shared/components/CustomModal";
import InventoryDetail from "./InventoryDetail";
import { useEffect, useState, useMemo, useRef } from "react";
import Table from "../../../shared/components/Table";
import {
  ACTION_LABEL,
  BUTTON_LABEL,
  icons,
  LABELS,
  SEARCH_DATA,
  toastError,
  toastSuccess,
  TOAST_MESSAGE,
} from "../../../shared/constants";
import {
  useInventoryGetQuery,
  useInventoryImportMutation,
  useInventoryListQuery,
} from "../../../Features/inventory/inventory-slice";
import Search from "../../../shared/components/Search";
import { skipToken } from "@reduxjs/toolkit/query";
import SimpleButton from "../../../shared/components/Button/Button";
import { dateConvert } from "../../../shared/helperFunc/dateConvert";
import { CSVLink } from "react-csv";
import authProvider from "../../../shared/config/authProvider";
import Filter from "../../Common/Filter/Filter";
import { useFilter } from "../../../shared/utils/hooks/useFilter";
import { Dropdown } from "react-bootstrap";

const Inventory = () => {
  const [search, setSearch] = useState(false);
  const [sort, setSort] = useState(SEARCH_DATA);
  const [inventoryList, setInventoryList] = useState([]);
  const [showDetail, setShowDetail] = useState(false);
  const [inventoryId, setInventoryId]: any = useState();
  const [count, setCount] = useState();
  const [inventoryDetailData, setInventoryDetailData] = useState();
  const [itemStockData, setItemStockData] = useState();
  const iconInputDetail = useRef<any>(null);
  const iconInputStock = useRef<any>(null);
  const [handleFilter] = useFilter(sort, setSort);
  const [showFilter, setShowFilter] = useState(false);
  const [filterCalled, setFilterCalled]: any = useState(false);
  const [filterSelectionError, setFilterSelectionError]: any = useState();
  const [filterValues, setFilterValues] = useState<any>({});

  const {
    data,
    isSuccess,
    refetch: inventoryRefetch,
    isFetching: inventoryListFetching,
  } = useInventoryListQuery(sort, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const {
    data: getInventoryData,
    isLoading: getInventoryLoading,
    isSuccess: getInventorySuccess,
  } = useInventoryGetQuery(inventoryId || skipToken, {
    refetchOnMountOrArgChange: true,
  });
  const [inventoryImport] = useInventoryImportMutation();

  // useEffect(() => {
  //   inventoryRefetch();
  // }, [filterCalled]);

  useEffect(() => {
    if (isSuccess) {
      setInventoryList(data.list);
      setCount(data.count);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (inventoryId && getInventorySuccess) {
      setInventoryDetailData(getInventoryData.item);
      setItemStockData(getInventoryData.stock);
    }
  }, [inventoryId, getInventoryData, getInventorySuccess]);

  const inventoryImportFlow = (data: any) => {
    inventoryRefetch();
    toastSuccess(data.message);
  };

  const handleCallFilter = () => {
    // if (Object.keys(sort.filter).length > 0) {
    handleFilter();
    setFilterCalled(!filterCalled);
    setSort({ ...sort, filter: filterValues, filterFlag: true, skip: 0 });
    setShowFilter(!showFilter);
    // } else {
    //   setFilterSelectionError("*Please select atlease one filter.");
    // }
  };

  const resetFilter = () => {
    setFilterValues({});
    setSort({ ...sort, filter: {}, filterFlag: false });
    setFilterCalled(!filterCalled);
    setFilterSelectionError("");
    setShowFilter(!showFilter);
  };

  const closeFilter = () => {
    setSort({ ...sort, filter: {}, filterFlag: false });
    setFilterCalled(!filterCalled);
    setFilterSelectionError("");
    setShowFilter(false);
  };

  const handleClose = () => {
    setShowDetail(false);
  };

  const handleSearch = () => {
    setSearch(!search);
    setSort({ ...sort, skip: 0 });
  };

  const handleSearchTextChange = (e: any) => {
    setSort({ ...sort, [e.target.name]: e.target.value, skip: 0 });
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleRef = (iconInputKey: string) => {
    const category = csvTypes.find((el: any) => el.type === iconInputKey);
    if (category && category.iconInput.current) {
      category.iconInput.current.click();
    }
  };

  const handleCsvImport = async (e: any, url: string) => {
    let formData = new FormData();
    formData.append("csv", e.target.files[0], e.target.files[0].name);
    formData.append("url", url);
    const inventoryImportResponse = await inventoryImport(formData)
      .unwrap()
      .catch((err: any) => {
        toastError(err.data?.errors[0]?.msg);
      });
    if (inventoryImportResponse && inventoryImportResponse?.status) {
      inventoryImportFlow(inventoryImportResponse);
    }
  };

  const handleOnBlur = (e: any) => {
    const trimmedValue = e.target.value.trim();

    setFilterValues({
      ...filterValues,
      [e.target.name]: trimmedValue,
    });
  };

  const handleTextChange = (e: any) => {
    setFilterValues({
      ...filterValues,
      [e.target.name]: e.target.value,
    });
  };

  const csvData: any = {};

  csvData.detail = [
    {
      ITEM_CODE: "111",
      CODE: "120",
      DIVISION: "FINISH GOODS",
      SECTION: "MENS (AROK)",
      DEPARTMENT: "SHERWANI",
      ARTICLE_NAME: "FINISH GOODS-MENS (AROK)-SHERWANI",
      CATEGORY1: "AROK",
      CATEGORY2: "ARK-10424",
      CATEGORY3: "FKEMB-917",
      CATEGORY4: "50",
      CATEGORY5: "125CMS",
      CATEGORY6: "FAWN",
      HSN_CODE: "62031200",
      MATERIAL_TYPE: "Finished Goods",
      MRP: "33480",
      RSP: "33480",
      STRING_DESC1: "SHERWANI-CHUDIDAR",
      STRING_DESC2: "ARK-10424 FKEMB-917 FAWN",
      STRING_DESC3: "",
      STRING_DESC4: "C104-COMPUTER EMB",
      STRING_DESC5: "",
      STRING_DESC6: "AROK DIV",
      UOM: "PCS",
      WSP: "17620",
      LAST_INWARD_RATE: "",
      CREATED_ON: "01/07/2024",
      LAST_MODIFIED_ON: "17/10/2024",
      STANDARD_RATE: "17620",
      LAST_STOCK_INWARD_DATE: "17/10/2024",
      BARCODE: "NMG100005",
      // STOCK_POINT: "",
      // SITE_CODE: "",
      // CLOSING_QTY: "",
    },
    {
      ITEM_CODE: "112",
      CODE: "122",
      DIVISION: "FINISH GOODS",
      SECTION: "MENS (AROK)",
      DEPARTMENT: "SHERWANI",
      ARTICLE_NAME: "FINISH GOODS-MENS (AROK)-SHERWANI",
      CATEGORY1: "AROK",
      CATEGORY2: "ARK-10424",
      CATEGORY3: "FKEMB-918",
      CATEGORY4: "36",
      CATEGORY5: "90CMS",
      CATEGORY6: "GREY",
      HSN_CODE: "62031200",
      MATERIAL_TYPE: "Finished Goods",
      MRP: "27900",
      RSP: "27900",
      STRING_DESC1: "SHERWANI-CHUDIDAR",
      STRING_DESC2: "ARK-10424 FKEMB-917 FAWN",
      STRING_DESC3: "",
      STRING_DESC4: "C104-COMPUTER EMB",
      STRING_DESC5: "",
      STRING_DESC6: "AROK DIV",
      UOM: "PCS",
      WSP: "14685",
      LAST_INWARD_RATE: "",
      CREATED_ON: "01/07/2024",
      LAST_MODIFIED_ON: "17/10/2024",
      STANDARD_RATE: "14685",
      LAST_STOCK_INWARD_DATE: "17/10/2024",
      BARCODE: "NMG100006",
      // STOCK_POINT: "",
      // SITE_CODE: "",
      // CLOSING_QTY: "",
    },
    {
      ITEM_CODE: "113",
      CODE: "133",
      DIVISION: "FINISH GOODS",
      SECTION: "MENS",
      DEPARTMENT: "SHIRT",
      ARTICLE_NAME: "FINISH GOODS-MENS-SHIRT",
      CATEGORY1: "ALBINO",
      CATEGORY2: "ASH-6263",
      CATEGORY3: "FSCCX-1064",
      CATEGORY4: "S",
      CATEGORY5: "90CMS",
      CATEGORY6: "BROWN",
      HSN_CODE: "62052000",
      MATERIAL_TYPE: "Finished Goods",
      MRP: "2995",
      RSP: "2995",
      STRING_DESC1: "F/S",
      STRING_DESC2: "ARK-10424 FKEMB-917 FAWN",
      STRING_DESC3: "",
      STRING_DESC4: "C314-COTTON CHECKS",
      STRING_DESC5: "",
      STRING_DESC6: "SHIRT DIV",
      UOM: "PCS",
      WSP: "1710",
      LAST_INWARD_RATE: "",
      CREATED_ON: "05/06/2024",
      LAST_MODIFIED_ON: "17/10/2024",
      STANDARD_RATE: "1710",
      LAST_STOCK_INWARD_DATE: "17/10/2024",
      BARCODE: "NMG100001",
      // STOCK_POINT: "",
      // SITE_CODE: "",
      // CLOSING_QTY: "",
    },
  ];

  csvData.stock = [
    {
      ITEM_CODE: "111",
      SITE_CODE: "213",
      CLOSING_QTY: "20",
    },
    {
      ITEM_CODE: "112",
      SITE_CODE: "123",
      CLOSING_QTY: "10",
    },
    {
      ITEM_CODE: "113",
      SITE_CODE: "211",
      CLOSING_QTY: "5",
    },
  ];

  const csvCategories: any = () => {
    return [
      {
        csvTitle: LABELS.ITEM_DETAIL_TEXT,
        type: "detail",
        url: "import",
        iconInput: iconInputDetail,
      },
      {
        csvTitle: LABELS.ITEM_STOCK_TEXT,
        type: "stock",
        url: "stock",
        iconInput: iconInputStock,
      },
    ];
  };

  const csvTypes = csvCategories();

  const csvBlock = (element: any) => {
    return (
      <CSVLink
        data={csvData[element.type]}
        filename={`inventory-${element.type}.csv`}
        className="w-auto px-0"
      >
        <a className="dropdown-item cursor-pointer"> {element.csvTitle}</a>
      </CSVLink>
    );
  };

  const importBlock = (element: any) => {
    return (
      <a
        className="dropdown-item cursor-pointer"
        onClick={() => handleRef(element.type)}
      >
        {element.csvTitle}
        <input
          type="file"
          style={{ display: "none" }}
          ref={element.iconInput}
          onChange={(e) => handleCsvImport(e, element.url)}
          accept=".csv"
          onClick={(e: any) => {
            e.target.value = null;
          }}
          hidden
        />
      </a>
    );
  };

  const handleInventoryDetails = (e: any, row: any) => {
    setInventoryId(row._id);
    setShowDetail(true);
  };

  const filterContent = (handleTextChange: any) => {
    return [
      // {
      //   label: LABELS.I_CODE_TEXT,
      //   name: ACTION_LABEL.ITEM_CODE,
      //   elementType: "text",
      //   action: handleTextChange,
      //   type: "",
      //   operator: "is from",
      // },
      {
        label: LABELS.DIVISION_TEXT,
        name: ACTION_LABEL.DIVISION,
        elementType: "text",
        action: handleTextChange,
        type: "",
        operator: "is from",
      },
      {
        label: LABELS.SECTION_TEXT,
        name: ACTION_LABEL.SECTION,
        elementType: "text",
        action: handleTextChange,
        type: "",
        operator: "is from",
      },
      {
        label: LABELS.DEPARMENT_TEXT,
        name: ACTION_LABEL.DEPARTMENT,
        elementType: "text",
        action: handleTextChange,
        type: "",
        operator: "is from",
      },
      {
        label: `${LABELS.CATEGORY_TEXT} 1`,
        name: `${ACTION_LABEL.CATEGORY}1`,
        elementType: "text",
        action: handleTextChange,
        type: "",
        operator: "is from",
      },
      {
        label: `${LABELS.CATEGORY_TEXT} 2`,
        name: `${ACTION_LABEL.CATEGORY}2`,
        elementType: "text",
        action: handleTextChange,
        type: "",
        operator: "is from",
      },
      {
        label: `${LABELS.CATEGORY_TEXT} 3`,
        name: `${ACTION_LABEL.CATEGORY}3`,
        elementType: "text",
        action: handleTextChange,
        type: "",
        operator: "is from",
      },
      {
        label: `${LABELS.CATEGORY_TEXT} 4`,
        name: `${ACTION_LABEL.CATEGORY}4`,
        elementType: "text",
        action: handleTextChange,
        type: "",
        operator: "is from",
      },
      {
        label: `${LABELS.CATEGORY_TEXT} 5`,
        name: `${ACTION_LABEL.CATEGORY}5`,
        elementType: "text",
        action: handleTextChange,
        type: "",
        operator: "is from",
      },
      {
        label: `${LABELS.CATEGORY_TEXT} 6`,
        name: `${ACTION_LABEL.CATEGORY}6`,
        elementType: "text",
        action: handleTextChange,
        type: "",
        operator: "is from",
      },
    ];
  };

  const inventoryModalButton = [
    {
      text: BUTTON_LABEL.CANCEL,
      action: handleClose,
      className: "btn btn theme-btn",
    },
  ];

  const columns = useMemo(
    () => [
      {
        header: LABELS.ITEM_CODE_TEXT,
        accessorKey: ACTION_LABEL.ITEM_CODE,
        isShow: true,
      },
      {
        header: LABELS.DIVISION_TEXT,
        accessorKey: ACTION_LABEL.DIVISION,
        isShow: true,
      },
      {
        header: LABELS.SECTION_TEXT,
        accessorKey: ACTION_LABEL.SECTION,
        isShow: true,
      },
      {
        header: LABELS.DEPARMENT_TEXT,
        accessorKey: ACTION_LABEL.DEPARTMENT,
        isShow: true,
      },
      {
        header: LABELS.ARTICLE_NAME_TEXT,
        accessorKey: ACTION_LABEL.ARTICLE_NAME,
        isShow: true,
      },
      {
        header: `${LABELS.CATEGORY_TEXT} 1`,
        accessorKey: `${ACTION_LABEL.CATEGORY}1`,
        isShow: true,
      },
      {
        header: `${LABELS.CATEGORY_TEXT} 2`,
        accessorKey: `${ACTION_LABEL.CATEGORY}2`,
        isShow: true,
      },
      {
        header: `${LABELS.CATEGORY_TEXT} 3`,
        accessorKey: `${ACTION_LABEL.CATEGORY}3`,
        isShow: true,
      },
      {
        header: `${LABELS.CATEGORY_TEXT} 4`,
        accessorKey: `${ACTION_LABEL.CATEGORY}4`,
        isShow: false,
      },
      {
        header: `${LABELS.CATEGORY_TEXT} 5`,
        accessorKey: `${ACTION_LABEL.CATEGORY}5`,
        isShow: false,
      },
      {
        header: `${LABELS.CATEGORY_TEXT} 6`,
        accessorKey: `${ACTION_LABEL.CATEGORY}6`,
        isShow: false,
      },
      {
        header: `${LABELS.CATEGORY_TEXT} ${LABELS.ALIAS_TEXT}`,
        accessorKey: `${ACTION_LABEL.DEPARTMENT}${ACTION_LABEL.ALIAS}`,
        isShow: false,
      },
      {
        header: LABELS.HSN_CODE_TEXT,
        accessorKey: ACTION_LABEL.HSN_CODE,
        isShow: false,
      },
      {
        header: LABELS.MATERIAL_TYPE_TEXT,
        accessorKey: ACTION_LABEL.MATERIAL_TYPE,
        isShow: false,
      },
      {
        header: LABELS.MRP_TEXT,
        accessorKey: ACTION_LABEL.MRP,
        isShow: false,
      },
      {
        header: LABELS.RSP_TEXT,
        accessorKey: ACTION_LABEL.RSP,
        isShow: false,
      },
      {
        header: `${LABELS.STRING_DESC_TEXT} 1`,
        accessorKey: `${ACTION_LABEL.STRING_DESC}1`,
        isShow: false,
      },
      {
        header: `${LABELS.STRING_DESC_TEXT} 2`,
        accessorKey: `${ACTION_LABEL.STRING_DESC}2`,
        isShow: false,
      },
      {
        header: `${LABELS.STRING_DESC_TEXT} 3`,
        accessorKey: `${ACTION_LABEL.STRING_DESC}3`,
        isShow: false,
      },
      {
        header: `${LABELS.STRING_DESC_TEXT} 4`,
        accessorKey: `${ACTION_LABEL.STRING_DESC}4`,
        isShow: false,
      },
      {
        header: `${LABELS.STRING_DESC_TEXT} 5`,
        accessorKey: `${ACTION_LABEL.STRING_DESC}5`,
        isShow: false,
      },
      {
        header: `${LABELS.STRING_DESC_TEXT} 6`,
        accessorKey: `${ACTION_LABEL.STRING_DESC}6`,
        isShow: false,
      },
      {
        header: LABELS.UOM_TEXT,
        accessorKey: ACTION_LABEL.UOM,
        isShow: false,
      },
      {
        header: LABELS.WSP_TEXT,
        accessorKey: ACTION_LABEL.WSP,
        isShow: false,
      },
      {
        header: LABELS.LAST_INWARD_RATE_TEXT,
        accessorKey: ACTION_LABEL.LAST_INWARD_RATE,
        isShow: false,
      },
      {
        header: LABELS.CREATED_ON_TEXT,
        accessorKey: ACTION_LABEL.CREATED_ON,
        cell: ({ row }: any) =>
          dateConvert(row.original[ACTION_LABEL.CREATED_ON]),
        isShow: false,
      },
      {
        header: LABELS.LAST_MODIFIED_ON_TEXT,
        accessorKey: ACTION_LABEL.LAST_MODIFIED_ON,
        cell: ({ row }: any) =>
          dateConvert(row.original[ACTION_LABEL.LAST_MODIFIED_ON]),
        isShow: false,
      },
      {
        header: LABELS.STANDARD_RATE_TEXT,
        accessorKey: ACTION_LABEL.STANDARD_RATE,
        isShow: false,
      },
      {
        header: LABELS.LAST_STOCK_INWARD_DATE_TEXT,
        accessorKey: ACTION_LABEL.LAST_STOCK_INWARD_DATE,
        cell: ({ row }: any) =>
          dateConvert(row.original[ACTION_LABEL.LAST_STOCK_INWARD_DATE]),
        isShow: false,
      },
      {
        header: LABELS.BARCODE_TEXT,
        accessorKey: ACTION_LABEL.BARCODE,
        isShow: false,
      },
      // {
      //   header: LABELS.STOCK_POINT_TEXT,
      //   accessorKey: ACTION_LABEL.STOCK_POINT,
      //   isShow: false,
      // },
      // {
      //   header: LABELS.SITE_CODE_TEXT,
      //   accessorKey: ACTION_LABEL.SITE_CODE,
      //   isShow: false,
      // },
      // {
      //   header: LABELS.CLOSING_QTY_TEXT,
      //   accessorKey: ACTION_LABEL.CLOSING_QTY,
      //   isShow: false,
      // },
    ],
    [sort]
  );

  return (
    <>
      <div className="full-height">
        <Navbar title={LABELS.INVENTORY_TEXT} />
        <main className="main-content">
          <div className="pt-3">
            <div className="container-fluid">
              <div className="row table-top-actions">
                <div className="col-12 col-md-12 d-flex justify-content-end">
                  <div className="d-flex align-items-center row w-100 mx-m-0">
                    <Search
                      placeholder={LABELS.SEARCH_TEXT}
                      name={ACTION_LABEL.SEARCH}
                      value={sort.q}
                      onChange={handleSearchTextChange}
                      onKeyDown={handleKeyDown}
                      onClick={handleSearch}
                    />

                    {authProvider.show("inventory", "edit") && (
                      <>
                        <Dropdown className="custom-dropdown d-flex align-items-center w-auto px-0">
                          <Dropdown.Toggle className="no-btn caret-hide">
                            <SimpleButton
                              datatoggle="tooltip"
                              dataplacement="top"
                              title={BUTTON_LABEL.IMPORT}
                              className={
                                "me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                              }
                              text={BUTTON_LABEL.IMPORT}
                            ></SimpleButton>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {csvTypes.map((el: any) => {
                              return importBlock(el);
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                        {/* <SimpleButton
                          datatoggle="tooltip"
                          dataplacement="top"
                          title={BUTTON_LABEL.IMPORT}
                          className={
                            "me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                          }
                          onClick={handleRef}
                          text={BUTTON_LABEL.IMPORT}
                        >
                          <input
                            type="file"
                            style={{ display: "none" }}
                            ref={iconInput}
                            onChange={handleCsvImport}
                            accept=".csv"
                            onClick={(e: any) => {
                              e.target.value = null;
                            }}
                            hidden
                          />
                        </SimpleButton> */}

                        <Dropdown className="custom-dropdown d-flex align-items-center w-auto px-0">
                          <Dropdown.Toggle className="no-btn caret-hide">
                            <SimpleButton
                              datatoggle="tooltip"
                              dataplacement="top"
                              title={BUTTON_LABEL.SAMPLE_CSV}
                              className={
                                "me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                              }
                              text={BUTTON_LABEL.SAMPLE_CSV}
                            />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {csvTypes.map((el: any) => {
                              return csvBlock(el);
                            })}
                          </Dropdown.Menu>
                        </Dropdown>

                        {/* <SimpleButton
                      className={
                        "me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                      }
                      // onClick={handleCsvExport}
                      text={BUTTON_LABEL.EXPORT}
                    /> */}
                      </>
                    )}

                    <SimpleButton
                      className={`me-2 btn theme-btn theme-btn-sm ${
                        Object.keys(sort.filter).length > 0 ? "" : "btn-inverse"
                      } filter-btn`}
                      btnIcon={icons.ICON_FILTER}
                      btnIconClass={"me-2 d-inline-block"}
                      onClick={handleCallFilter}
                    />
                  </div>
                </div>
              </div>
              <div className="card custom-card card-full mt-3">
                <div className="card-body p-0">
                  <Table
                    data={inventoryList}
                    columns={columns}
                    sort={sort}
                    setSort={setSort}
                    coloumnDrop={true}
                    count={count}
                    isFetching={inventoryListFetching}
                    handleRow={handleInventoryDetails}
                    isHandleRowEnabled
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <CustomModal
        show={showDetail}
        close={handleClose}
        size={"lg"}
        message={""}
        modalTitle={LABELS.INVENTORY_MANAGEMENT_DETAILS_TEXT}
        modalButton={inventoryModalButton}
        fullscreen={undefined}
        className={"light-modal inventory-detail-modal"}
        centerClass={""}
        modalTitleIcon={""}
      >
        <InventoryDetail
          itemStockData={itemStockData}
          inventoryDetailData={inventoryDetailData}
          getInventoryLoading={getInventoryLoading}
        />
      </CustomModal>

      <Filter
        sort={filterValues}
        filterSelectionError={filterSelectionError}
        filterObject={filterContent(handleTextChange)}
        show={showFilter}
        onClose={closeFilter}
        handleReset={resetFilter}
        handleApply={handleCallFilter}
        handleOnBlur={handleOnBlur}
      />
    </>
  );
};

export default Inventory;
