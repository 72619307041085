import { useState } from "react";
import { Link } from "react-router-dom";
import OtpInput from "react-otp-input";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { BUTTON_LABEL, LABELS } from "../shared/constants";
const SetOtp = (props: any) => {
  const { resetData, handleOnChange, error, handleValidateOtp, setError } =
    props;

  return (
    <>
      <Form>
        <div className="otp-control-group">
          <Form.Group className="form-group">
            <Form.Label>{LABELS.CONFIRMATION_CODE_TEXT}</Form.Label>
            <div className="otp-controls">
              <OtpInput
                value={resetData.otp}
                onChange={handleOnChange}
                numInputs={6}
                // renderSeparator={<span>-</span>}
                renderInput={(props) => (
                  <input {...props} className="form-control" />
                )}
              />
              {/* <Form.Control
                type="otp"
                inputMode="text"
                id="otp"
                name="otp"
                value={resetData.otp}
                onChange={handleOnChange}
              />
              <Form.Control
                type="otp"
                inputMode="text"
                id="otp"
                name="otp"
                value={resetData.otp}
                onChange={handleOnChange}
              />
              <Form.Control
                type="otp"
                inputMode="text"
                id="otp"
                name="otp"
                value={resetData.otp}
                onChange={handleOnChange}
              />
              <Form.Control
                type="otp"
                inputMode="text"
                id="otp"
                name="otp"
                value={resetData.otp}
                onChange={handleOnChange}
              />
              <Form.Control
                type="otp"
                inputMode="text"
                id="otp"
                name="otp"
                value={resetData.otp}
                onChange={handleOnChange}
              />
              <Form.Control
                type="otp"
                inputMode="text"
                id="otp"
                name="otp"
                value={resetData.otp}
                onChange={handleOnChange}
              /> */}
            </div>
            {/* <Link to="/new-password" className="text-end float-end mt-2"> Resend Code</Link> */}
          </Form.Group>
          <div className="text-error">
            {error?.otp || "*"}
            {error?.otp || ""}
          </div>
        </div>
        <div className="text-center mt-5">
          <Link
            // to="/setpassword"
            to="#"
            className="btn theme-btn text-uppercase"
            onClick={handleValidateOtp}
          >
            {BUTTON_LABEL.SUBMIT}
          </Link>
        </div>
      </Form>
    </>
  );
};

export default SetOtp;
