import { FC } from "react";

interface CheckBoxProps {
  label: string | any;
  value: boolean | any;
  onChange: (event: any) => any;
  name: string | any;
  disabled?: boolean;
  className?: string;
  labelClass?: string;
  id: string;
}

const CheckBox: FC<CheckBoxProps> = (props: any) => {
  const { label, value, onChange, name, disabled, className, labelClass, id } =
    props;

  return (
    <>
      <div className="custom-checkbox">
        <input
          type="checkbox"
          id={id}
          checked={value}
          onChange={onChange}
          name={name}
          className={className || "custom-control-input"}
          disabled={disabled ? true : false}
        />
        &nbsp;
        <label htmlFor={id} className={labelClass || "custom-control-label"}>
          {label}
        </label>
      </div>
    </>
  );
};

export default CheckBox;
