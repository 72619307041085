import { skipToken } from "@reduxjs/toolkit/query";
import { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { Logo } from "../../../../../App/assets/img";
import {
  useOrderGetQuery,
  usePrintOrderMutation,
} from "../../../../../Features/order/order-slice";
import SimpleButton from "../../../../../shared/components/Button/Button";
import CustomModal from "../../../../../shared/components/CustomModal";
import DatePickerComponent from "../../../../../shared/components/DatePicker/DatePicker";
import TextArea from "../../../../../shared/components/TextArea";
import TextBox from "../../../../../shared/components/TextBox";
import {
  ACTION_LABEL,
  BUTTON_LABEL,
  LABELS,
  MESSAGE,
  toastError,
  toastSuccess,
} from "../../../../../shared/constants";
import {
  bottomMeasurement,
  topMeasurement,
} from "../../../../../shared/constants/resource";
import { getMeasurementIds } from "../../../../../shared/helperFunc/getMeasurementByIds";
import ConfirmPrint from "../ConfirmPrint";

const PrintOrder = (props: any) => {
  const {
    id,
    data,
    currentRow,
    setShowPrintOrder,
    handleClosePrintOrder,
    refetchOrderApi,
  } = props;
  const contentRef = useRef<HTMLDivElement>(null);
  console.log(`currentRow`, currentRow);
  const [printOrderData, setPrintOrderData] = useState({
    srNo: currentRow.documentNo,
    // cus: currentRow.firstName + " " + currentRow.lastName,
    storeName: currentRow.store?.name,
    dNo: currentRow.designNo,
    date: currentRow.deliveryDate,
    remark: currentRow.remarks,
  });
  const [errors, setErrors]: any = useState({});
  const [showConfirmPrintModal, setShowConfirmPrintModal] = useState(false);

  let orderId = currentRow._id;

  const {
    data: getOrderData,
    isLoading: getOrderLoading,
    isSuccess: getOrderSuccess,
    refetch: getOrderRefetch,
  } = useOrderGetQuery(orderId || skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const [printOrder, { isLoading: printOrderLoading }] =
    usePrintOrderMutation();

  const printData = useReactToPrint({ contentRef });

  const printOrderFlow = async (data: any) => {
    toastSuccess(data.message);
    setShowConfirmPrintModal(!showConfirmPrintModal);
    printData();
    getOrderRefetch();
    refetchOrderApi();
    setShowPrintOrder(false);
  };

  const handleOnChange = (e: any) => {
    setPrintOrderData({ ...printOrderData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleDateChange = (value: any) => {
    setPrintOrderData({ ...printOrderData, date: value });
    setErrors({ ...errors, date: "" });
  };

  const handleConfirm = () => {
    setShowConfirmPrintModal(!showConfirmPrintModal);
  };

  const handlePrint = async () => {
    let measurement: any = {};
    if (data?.top?.length) {
      measurement.top = getMeasurementIds(
        data.top.map((item: any) => item.type),
        topMeasurement
      );
    }

    if (data?.bottom?.length) {
      measurement.bottom = getMeasurementIds(
        data.bottom.map((item: any) => item.type),
        bottomMeasurement
      );
    }

    if (data?.custom) {
      measurement.custom = Object.keys(data?.custom);
    }

    let payload: any = {
      itemCode: data?.itemJson.itemCode,
    };

    if (Object.keys(measurement).length > 0) {
      payload.measurement = measurement;
    }
    const printOrderResponse = await printOrder({
      id: id,
      data: payload,
    })
      .unwrap()
      .catch((err: any) => {
        toastError(err.data?.errors[0]?.msg);
      });
    if (printOrderResponse && printOrderResponse?.status) {
      printOrderFlow(printOrderResponse);
    }
  };

  const closeConfirmPrintModal = () => {
    setShowConfirmPrintModal(!showConfirmPrintModal);
  };

  const confirmPrintModal = (handlePrint: any, closeModal: any) => {
    return [
      {
        text: BUTTON_LABEL.YES,
        action: handlePrint,
        className: "btn theme-btn theme-btn-sm w-auto",
      },
      {
        text: BUTTON_LABEL.NO,
        action: closeModal,
        className: "btn theme-btn theme-btn-sm btn-inverse w-auto",
      },
    ];
  };

  const confirmPrintModalButton = confirmPrintModal(
    handlePrint,
    closeConfirmPrintModal
  );

  const measurementStandardBlock = (item: any) => {
    return (
      <div className="value">
        <label htmlFor="">{item?.title}</label>
        <span className="label-box">{item?.value}</span>
      </div>
    );
  };

  const customMeasurementBlock = (title: any, value: any) => {
    if (value) {
      return (
        <div className="item-card mb-2">
          <div className="item-header ">
            <span>{title}</span>
          </div>
          <div className="item-body">
            <div className="populate-values">
              <div className="value">
                <span className="label-box">{value}</span>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div>
        <div ref={contentRef} className="print-page">
          <div className="pritable-area">
            <div className="row">
              <div className="col-md-2">
                <img src={Logo} alt="lock" width={180} />
              </div>
              <div className="col-md-10 text-center">
                <h3>{LABELS.PRINT_ORDER_TITLE_TEXT}</h3>
                <p>
                  {LABELS.PRINT_ORDER_HOUSE_ADDRESS_TEXT} <br />
                  {LABELS.PRINT_ORDER_ADDRESS_TEXT} <br />
                  {LABELS.PRINT_ORDER_PHONE_TEXT}
                </p>
              </div>
            </div>
            <div className="inner">
              <div className="row mb-3">
                <div className="col-12 col-md-5">
                  <div className="d-flex align-items-center">
                    <TextBox
                      name={ACTION_LABEL.SR_NO}
                      placeholder={LABELS.SR_NO_TEXT}
                      id={LABELS.SR_NO_TEXT}
                      label={LABELS.SR_NO_TEXT}
                      onChange={handleOnChange}
                      value={printOrderData.srNo}
                      errorMessage={errors.srNo || null}
                    />
                    {/* <label htmlFor="">Sr. No.</label>
                <input type="text" className="form-control" placeholder="38062" /> */}
                  </div>
                </div>
                <div className="col-12 col-md-5 ms-auto">
                  <div className="d-flex align-items-center">
                    <DatePickerComponent
                      // label={LABELS.DATE_TEXT}
                      label={LABELS.DISPATCH_DATE_TEXT}
                      value={printOrderData.date}
                      onChange={handleDateChange}
                      placeholder={"DD/MM/YYYY"}
                      format="dd/MM/yyyy"
                      placement="bottom-start"
                      errorMessage={errors.date || null}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="row mb-3">
                <div className="col-12">
                  <div className="d-flex align-items-center">
                    <TextBox
                      name={ACTION_LABEL.CUS}
                      placeholder={LABELS.CUS_TEXT}
                      id={LABELS.CUS_TEXT}
                      label={LABELS.CUS_TEXT}
                      onChange={handleOnChange}
                      value={printOrderData.cus}
                      errorMessage={errors.cus || null}
                    />
                  </div>
                </div>
              </div> */}
              <div className="row mb-3">
                <div className="col-12">
                  <div className="d-flex align-items-center">
                    <TextBox
                      // name={ACTION_LABEL.STORE}
                      placeholder={LABELS.STORE_CODE_TEXT}
                      id={LABELS.STORE_CODE_TEXT}
                      label={LABELS.STORE_CODE_TEXT}
                      // onChange={handleOnChange}
                      value={printOrderData.storeName}
                      errorMessage={errors.storeName || null}
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <div className="d-flex align-items-center">
                    <TextBox
                      name={ACTION_LABEL.D_NO}
                      placeholder={LABELS.D_NO_TEXT}
                      id={LABELS.D_NO_TEXT}
                      label={LABELS.D_NO_TEXT}
                      onChange={handleOnChange}
                      value={printOrderData.dNo}
                      errorMessage={errors.dNo || null}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {Array.isArray(data?.top) &&
                    data.top.map((data: any) => {
                      return (
                        <div className="item-card mb-2 page-break">
                          <div className="item-header">
                            <span>{data.type}</span>
                          </div>
                          <div className="item-body">
                            <div className="populate-values">
                              <div className="populate-values">
                                {Array.isArray(data?.data) &&
                                  data.data.map((el: any) => {
                                    return measurementStandardBlock(el);
                                  })}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                  {Array.isArray(data?.bottom) &&
                    data.bottom.map((data: any) => {
                      return (
                        <div className="item-card mb-2">
                          <div className="item-header">
                            <span>{data.type}</span>
                          </div>
                          <div className="item-body">
                            <div className="populate-values">
                              <div className="populate-values">
                                {Array.isArray(data?.data) &&
                                  data.data.map((el: any) => {
                                    return measurementStandardBlock(el);
                                  })}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                  {data?.custom &&
                    typeof data?.custom === "object" &&
                    Object.keys(data?.custom).map((ele: any, index: any) => {
                      return customMeasurementBlock(ele, data?.custom[ele]);

                      // return data?.custom[ele]  ? (
                      //   <div className="item-card mb-2">
                      //     <div className="item-header ">
                      //       <span>{ele}</span>
                      //     </div>
                      //     <div className="item-body">
                      //       <div className="populate-values">
                      //         <div className="value">
                      //           <span className="label-box">
                      //             {data?.custom[ele]}
                      //           </span>
                      //         </div>
                      //       </div>
                      //     </div>
                      //   </div>
                      // ) : (
                      //   ""
                      // );
                    })}
                </div>
              </div>
              <div className="form-group">
                <TextArea
                  name={ACTION_LABEL.REMARK}
                  placeholder={LABELS.REMARKS_TEXT}
                  id={LABELS.REMARKS_TEXT}
                  label={LABELS.REMARKS_TEXT}
                  onChange={handleOnChange}
                  value={printOrderData.remark}
                  errorMessage={errors.remark || null}
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <SimpleButton
            className="btn theme-btn theme-btn-sm me-2"
            onClick={handleConfirm}
            text={BUTTON_LABEL.PRINT}
          />
          {/* <button className="btn btn theme-btn me-3">
          <span>Print</span>
        </button> */}
          <SimpleButton
            className="btn theme-btn theme-btn-sm me-2"
            onClick={handleClosePrintOrder}
            text={BUTTON_LABEL.CLOSE}
          />
          {/* <button className="btn btn theme-btn btn-inverse">
          <span>Close</span>
        </button> */}
        </div>
      </div>

      <CustomModal
        show={showConfirmPrintModal}
        close={closeConfirmPrintModal}
        message={""}
        size="md"
        modalTitle={LABELS.PRINT_CONFIRMATION_TEXT}
        modalButton={confirmPrintModalButton}
        fullscreen={undefined}
        className={""}
        centerClass={""}
        modalTitleIcon={""}
      >
        <ConfirmPrint itemPrintData={data} />
      </CustomModal>
    </>
  );
};

export default PrintOrder;
