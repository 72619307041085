import { useEffect, useState } from "react";
import { useOrderGetQuery } from "../../../Features/order/order-slice";
import SimpleButton from "../../../shared/components/Button/Button";
import { BUTTON_LABEL, icons, LABELS } from "../../../shared/constants";
import { Offcanvas } from "react-bootstrap";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  bottomMeasurement,
  topMeasurement,
} from "../../../shared/constants/resource";
import { getSingleMeasurementById } from "../../../shared/helperFunc/getMeasurementByIds";

const PopulateBar = (props: any) => {
  const { show, orderId, closePopulateBar, handlePrintOrder, populateData } =
    props;
  const [orderDetailData, setOrderDetailData]: any = useState();
  const [populateMeasurementIndex, setPopulateMeasurementIndex] = useState();

  const {
    data: getOrderData,
    isLoading: getOrderLoading,
    isSuccess: getOrderSuccess,
    refetch: getOrderRefetch,
  } = useOrderGetQuery(orderId || skipToken, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (orderId && getOrderSuccess) {
      setOrderDetailData(getOrderData.item);
    }
  }, [orderId, getOrderData, getOrderSuccess]);

  const getPopulateCounter = (
    itemJson: any,
    measurementArray: any,
    key: string,
    el: any
  ) => {
    const matchedEl = getSingleMeasurementById(measurementArray, el);
    return itemJson?.printCounter?.[key]?.[matchedEl] || 0;
  };

  const measurementStandardBlock = (item: any) => {
    return (
      <div className="value">
        <label htmlFor="">{item?.title}</label>
        <span className="label-box">{item?.value}</span>
      </div>
    );
  };

  const toggleShowPopulate = (index: any) => {
    setPopulateMeasurementIndex(index);
  };

  const toggleHidePopulate = () => {
    setPopulateMeasurementIndex(undefined);
  };

  const customMeasurementBlock = (title: any, value: any, itemJson: any) => {
    if (value) {
      const count = itemJson?.printCounter?.["custom"]?.[title] || 0;
      return (
        <div className="item-card">
          <div className="item-header ">
            <span>{title}</span>
            <div className="d-flex aling-items-center">
              {count > 0 && <span className="counts me-2">{count}</span>}
              <span
                onClick={() => printData("single", title, "custom", itemJson)}
              >
                <i className={icons.ICON_PRINTER_FILLED}></i>
              </span>
            </div>
          </div>
          <div className="item-body">
            <div className="populate-values">
              <div className="value">
                <span className="label-box">{value}</span>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const printData = (
    type: any,
    index: any,
    section: any,
    itemJson: any = {}
  ) => {
    let dataToPrint: any = { itemJson: itemJson };

    var itemData = orderDetailData?.items?.find(
      (ele: any) => ele.itemCode === itemJson.itemCode
    );

    if (type === "single") {
      if (section === "top" && itemData?.measurement?.top) {
        dataToPrint.top = [itemData?.measurement?.top[index]];
      } else if (section === "bottom" && itemData?.measurement?.bottom) {
        dataToPrint.bottom = [itemData?.measurement?.bottom[index]];
      } else if (section === "custom") {
        dataToPrint.custom = {
          [index]: itemData?.measurement?.custom[index],
        };
      }
    } else if (type === "multiple") {
      dataToPrint.top = itemData.measurement.top;
      dataToPrint.bottom = itemData.measurement.bottom;
      dataToPrint.custom = itemData.measurement.custom;
    }

    handlePrintOrder(dataToPrint);
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={closePopulateBar}
        // backdrop="true"
        placement="end"
        className="populate-bar-wrapper"
      >
        <div className={`populate-bar ${show}`}>
          <div
            className="populate-bar-header align-items-center d-flex"
            onClick={closePopulateBar}
          >
            <p className="cursor-pointer">
              <i className={icons.ICON_CHEVRON_LEFT}></i>
              <span>
                {LABELS.POPULATE_TEXT} - {populateData?.documentNo}
              </span>
            </p>
          </div>
          <Offcanvas.Body className="filter-body">
            <div className="populate-bar-body custom-scrollbar p-0">
              {populateData?.items?.map(
                (populateItem: any, populateIndex: any) => {
                  return (
                    <>
                      <div className="populate-main-item">
                        {!populateData || populateData?.items?.length < 1 ? (
                          <span>{LABELS.NOT_FOUND_TEXT}</span>
                        ) : (
                          <>
                            <div
                              className="populate-header "
                              onClick={
                                populateMeasurementIndex === populateIndex
                                  ? () => toggleHidePopulate()
                                  : () => toggleShowPopulate(populateIndex)
                              }
                            >
                              <strong>{populateItem.itemCode}</strong>
                              <i
                                className={`${
                                  populateMeasurementIndex === populateIndex
                                    ? icons.ICON_CHEVRON_UP
                                    : icons.ICON_CHEVRON_DOWN
                                } cursor-pointer`}
                              ></i>
                            </div>
                            <div
                              className={`populate-item-wrapper ${
                                populateMeasurementIndex === populateIndex &&
                                "show"
                              }`}
                            >
                              {populateItem?.measurement.top?.length > 0 &&
                                populateItem?.measurement?.top?.map(
                                  (topEl: any, index: any) => {
                                    const count = getPopulateCounter(
                                      populateItem,
                                      topMeasurement,
                                      "top",
                                      topEl
                                    );
                                    return (
                                      <div className="item-card">
                                        <div className="item-header ">
                                          <span>{topEl.type}</span>
                                          <div className="d-flex aling-items-center">
                                            {count > 0 && (
                                              <span className="counts me-2">
                                                {count}
                                              </span>
                                            )}
                                            <span
                                              onClick={() =>
                                                printData(
                                                  "single",
                                                  index,
                                                  "top",
                                                  populateItem
                                                )
                                              }
                                            >
                                              <i
                                                className={
                                                  icons.ICON_PRINTER_FILLED
                                                }
                                              ></i>
                                            </span>
                                          </div>
                                        </div>
                                        <div className="item-body">
                                          <div className="populate-values">
                                            {topEl.data.map((el: any) => {
                                              return measurementStandardBlock(
                                                el
                                              );
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              {populateItem?.measurement?.bottom?.length > 0 &&
                                populateItem?.measurement?.bottom?.map(
                                  (bottomEl: any, index: any) => {
                                    const count = getPopulateCounter(
                                      populateItem,
                                      bottomMeasurement,
                                      "bottom",
                                      bottomEl
                                    );
                                    return (
                                      <div className="item-card">
                                        <div className="item-header ">
                                          <span>{bottomEl.type}</span>

                                          <div className="d-flex aling-items-center">
                                            {count > 0 && (
                                              <span className="counts me-2">
                                                {count}
                                              </span>
                                            )}
                                            <span
                                              onClick={() =>
                                                printData(
                                                  "single",
                                                  index,
                                                  "bottom",
                                                  populateItem
                                                )
                                              }
                                            >
                                              {/* <p>{LABELS.PRINT_ALL_TEXT}</p> */}
                                              <i
                                                className={
                                                  icons.ICON_PRINTER_FILLED
                                                }
                                              ></i>
                                            </span>
                                          </div>
                                        </div>
                                        <div className="item-body">
                                          <div className="populate-values">
                                            {bottomEl.data.map((el: any) => {
                                              return measurementStandardBlock(
                                                el
                                              );
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}

                              {populateItem?.measurement?.custom &&
                                typeof populateItem?.measurement?.custom ===
                                  "object" &&
                                Object.keys(
                                  populateItem?.measurement?.custom
                                ).map((ele: any, index: any) => {
                                  return customMeasurementBlock(
                                    ele,
                                    populateItem?.measurement?.custom[ele],
                                    populateItem
                                  );

                                  // return populateData?.custom[ele] && (
                                  //   <div className="item-card">
                                  //     <div className="item-header ">
                                  //       <span>{ele}</span>
                                  //       <span
                                  //         onClick={() => printData("single", ele, "custom")}
                                  //       >
                                  //         <i className={icons.ICON_PRINTER_FILLED}></i>
                                  //       </span>
                                  //     </div>
                                  //     <div className="item-body">
                                  //       <div className="populate-values">
                                  //         <div className="value">
                                  //           <span className="label-box">
                                  //             {populateData?.custom[ele]}
                                  //           </span>
                                  //         </div>
                                  //       </div>
                                  //     </div>
                                  //   </div>
                                  // );
                                })}

                              <div
                                className="d-flex align-items-center cursor-pointer"
                                onClick={() =>
                                  printData(
                                    "multiple",
                                    null,
                                    null,
                                    populateItem
                                  )
                                }
                              >
                                {/* <CheckBox
                  onChange={handlePrintAllToggle}
                  value={printAll}
                  label={"Print All"}
                  name={"printAll"}
                  id={"printAll"}
                /> */}
                                {LABELS.PRINT_ALL_TEXT}
                                <i
                                  className={`${icons.ICON_PRINTER_FILLED}  ms-2`}
                                ></i>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  );
                }
              )}
              <div className="text-center mt-5">
                <SimpleButton
                  className={"btn theme-btn theme-btn-sm"}
                  onClick={closePopulateBar}
                  text={BUTTON_LABEL.CLOSE}
                />
              </div>
            </div>
          </Offcanvas.Body>
        </div>
      </Offcanvas>
    </>
  );
};

export default PopulateBar;
