import React from "react";
import { LogoLoader } from "../../../App/assets/img";
const Loader = (props: any) => {
  const { isAuto } = props;
  return (
    <div className={`main-loader ${isAuto && "min-height-auto"}`}>
      <img src={LogoLoader} alt="Kora" />
    </div>
  );
};

export default Loader;
