import { icons } from "../../constants";

const MultiDeleteBlock = (props: any) => {
  const { multiDelete, removeMultiDelete, totalLength, handleOnClick, count } =
    props;

  return (
    <>
      {multiDelete > 0 && count > 0 && totalLength > 0 && (
        <div className="sticky-action">
          <span>{multiDelete} items selected</span>
          <i
            className={`icon ${icons.ICON_CLOSE}`}
            onClick={removeMultiDelete}
          ></i>
          <i
            className={`icon ${icons.ICON_DELETE}`}
            onClick={handleOnClick}
          ></i>
        </div>
      )}
    </>
  );
};

export default MultiDeleteBlock;
