import { useEffect, useMemo, useRef, useState } from "react";
import CustomModal from "../../../shared/components/CustomModal";
import ReportDetail from "./ReportDetail";
import {
  ACTION_LABEL,
  BUTTON_LABEL,
  icons,
  LABELS,
  SEARCH_DATA,
  toastError,
  toastSuccess,
} from "../../../shared/constants";
import Navbar from "../../Navbar/NavbarComponent";
import Search from "../../../shared/components/Search";
import Table from "../../../shared/components/Table";
import {
  useReportFollowUpQuery,
  useReportImportMutation,
  useReportListQuery,
} from "../../../Features/report/report-slice";
import { skipToken } from "@reduxjs/toolkit/query";
import SimpleButton from "../../../shared/components/Button/Button";
import { secondDateConvert } from "../../../shared/helperFunc/dateConvert";
import Filter from "../../Common/Filter/Filter";
import { useFilter } from "../../../shared/utils/hooks/useFilter";
import moment from "moment";
import { CSVLink } from "react-csv";

const ReportList = (props: any) => {
  const {} = props;

  const [search, setSearch] = useState(false);
  const [sort, setSort]: any = useState(SEARCH_DATA);
  const [reportFollowUpList, setReportFollowUpList] = useState([]);
  const [showReportModal, setShowReportModal] = useState(false);
  const [soNo, setsoNo]: any = useState();
  const [reportDetailData, setReportDetailData]: any = useState();
  const [count, setCount] = useState();
  const iconInput = useRef<any>(null);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [handleFilter] = useFilter(sort, setSort);
  const [showFilter, setShowFilter] = useState(false);
  const [filterCalled, setFilterCalled]: any = useState(false);
  const [filterSelectionError, setFilterSelectionError]: any = useState();
  const [filterValues, setFilterValues] = useState<any>({});

  const {
    data,
    isSuccess,
    refetch: reportRefetch,
    error,
    isFetching: reportListFetching,
  } = useReportListQuery(
    { ...sort, sortBy: ACTION_LABEL.STORE_DELIVERY_DATE },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: getReportData,
    isLoading: getReportLoading,
    isSuccess: getReportSuccess,
  } = useReportFollowUpQuery(soNo ? { soNo: soNo } : skipToken, {
    refetchOnMountOrArgChange: true,
  });

  const [reportImport, { isLoading: reportImportLoading }] =
    useReportImportMutation();

  useEffect(() => {
    if (isSuccess) {
      setReportFollowUpList(data.list);
      setCount(data.count);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (soNo && getReportSuccess) {
      setReportDetailData(getReportData?.item);
    }
  }, [soNo, getReportData, getReportSuccess]);

  const reportImportFlow = (data: any) => {
    reportRefetch();
    toastSuccess(data.message);
  };

  const handleCallFilter = () => {
    // if (Object.keys(sort.filter).length > 0) {
    handleFilter();
    setFilterCalled(!filterCalled);
    setSort({ ...sort, filter: filterValues, filterFlag: true, skip: 0 });
    setShowFilter(!showFilter);
    // } else {
    //   setFilterSelectionError("*Please select atlease one filter.");
    // }
  };

  const resetFilter = () => {
    setFilterValues({});
    setSort({ ...sort, filter: {}, filterFlag: false });
    setFilterCalled(!filterCalled);
    setFilterSelectionError("");
    setShowFilter(!showFilter);
  };

  const closeFilter = () => {
    setSort({ ...sort, filter: {}, filterFlag: false });
    setFilterCalled(!filterCalled);
    setFilterSelectionError("");
    setShowFilter(false);
  };

  const closeReportModal = () => {
    setShowReportModal(false);
    setReportDetailData();
    setsoNo();
  };

  const handleSearch = () => {
    setSearch(!search);
    setSort({ ...sort, skip: 0 });
  };

  const handleSearchTextChange = (e: any) => {
    setSort({ ...sort, [e.target.name]: e.target.value, skip: 0 });
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleReportDetails = (e: any, row: any) => {
    setsoNo(row.soNo);
    setShowReportModal(true);
  };

  const handleRef = () => {
    iconInput.current.click();
  };

  const handleCsvImport = async (e: any) => {
    let formData = new FormData();
    formData.append("csv", e.target.files[0], e.target.files[0].name);
    const reportImportResponse = await reportImport(formData)
      .unwrap()
      .catch((err: any) => {
        toastError(err.data?.errors[0]?.msg);
      });
    if (reportImportResponse && reportImportResponse?.status) {
      reportImportFlow(reportImportResponse);
    }
  };

  const reportDetailModalButton = [
    {
      text: BUTTON_LABEL.CANCEL,
      action: closeReportModal,
      className: "btn btn theme-btn",
    },
  ];

  const filterModalButton = [
    {
      text: BUTTON_LABEL.APPLY,
      action: closeReportModal,
      className: "btn btn theme-btn",
    },
    {
      text: BUTTON_LABEL.RESET,
      action: closeReportModal,
      className: "btn btn theme-btn",
    },
  ];

  const handleOnBlur = (e: any) => {
    const trimmedValue = e.target.value.trim();

    setFilterValues({
      ...filterValues,
      [e.target.name]: trimmedValue,
    });
  };

  const handleDate = (dates: any) => {
    const [start, end] = dates;

    const fromDate = start ? new Date(start).toISOString() : null;
    const toDate = end ? new Date(end).toISOString() : null;

    setFilterValues({
      ...filterValues,
      createdAt: { from: fromDate, to: toDate },
    });
  };

  const csvData = [
    {
      SO_NO: "249474",
      SO_DATE: "02-12-2024",
      CUSTOMER: "GINNI RETAIL-ESPLANDE",
      ORDER_TYPE: "MTM",
      DELIVERY_ST_DATE: "03-01-2025",
      DIV: "FINISH GOODS",
      DEPARTMENT: "SHIRT",
      BARCODE: "HM103",
      DESIGN: "HM101-786",
      COLOUR: "Blue",
      SIZE: "42",
      SUB_ASSEMBLY_ITEM: "Shirt",
      PROCESS: "Cutting",
      JOB_ORDER_NO: "JOB/00061647/24-25",
      JOB_ORDER_DATE: "05-12-2024",
      JOB_RECEIPT_DATE: "05-12-2024",
      JOB_ORDER_QTY: "1",
      JOB_COMPLETED_QTY: "0",
      JOB_CNL_QTY: "0",
      JOB_PENDING_QTY: "1",
    },
    {
      SO_NO: "249475",
      SO_DATE: "05-12-2024",
      CUSTOMER: "GINNI RETAIL-ESPLANDE",
      ORDER_TYPE: "MTM",
      DELIVERY_ST_DATE: "05-01-2025",
      DIV: "FINISH GOODS",
      DEPARTMENT: "KURTA",
      BARCODE: "HM101",
      DESIGN: "HM102-786",
      COLOUR: "Yellow",
      SIZE: "44",
      SUB_ASSEMBLY_ITEM: "Kurta",
      PROCESS: "Embroidery",
      JOB_ORDER_NO: "JOB/00061648/24-25",
      JOB_ORDER_DATE: "09-12-2024",
      JOB_RECEIPT_DATE: "09-12-2024",
      JOB_ORDER_QTY: "1",
      JOB_COMPLETED_QTY: "0",
      JOB_CNL_QTY: "1",
      JOB_PENDING_QTY: "1",
    },
    {
      SO_NO: "249476",
      SO_DATE: "08-12-2024",
      CUSTOMER: "Optculture Store",
      ORDER_TYPE: "MTM",
      DELIVERY_ST_DATE: "10-01-2025",
      DIV: "FINISH GOODS",
      DEPARTMENT: "PANT",
      BARCODE: "HM102",
      DESIGN: "HM103-786",
      COLOUR: "Blue",
      SIZE: "36",
      SUB_ASSEMBLY_ITEM: "Pant",
      PROCESS: "Cutting",
      JOB_ORDER_NO: "JOB/00061649/24-25",
      JOB_ORDER_DATE: "15-12-2024",
      JOB_RECEIPT_DATE: "15-12-2024",
      JOB_ORDER_QTY: "1",
      JOB_COMPLETED_QTY: "1",
      JOB_CNL_QTY: "0",
      JOB_PENDING_QTY: "0",
    },
    {
      SO_NO: "249478",
      SO_DATE: "04-01-2025",
      CUSTOMER: "Optculture Store",
      ORDER_TYPE: "MTM",
      DELIVERY_ST_DATE: "02-02-2025",
      DIV: "FINISH GOODS",
      DEPARTMENT: "SHIRT",
      BARCODE: "HM105",
      DESIGN: "HM105-786",
      COLOUR: "Red",
      SIZE: "40",
      SUB_ASSEMBLY_ITEM: "Shirt",
      PROCESS: "Stitching",
      JOB_ORDER_NO: "JOB/00061650/24-25",
      JOB_ORDER_DATE: "14-01-2025",
      JOB_RECEIPT_DATE: "14-01-2025",
      JOB_ORDER_QTY: "1",
      JOB_COMPLETED_QTY: "0",
      JOB_CNL_QTY: "0",
      JOB_PENDING_QTY: "1",
    },
  ];

  const filterContent = (handleDate: any) => {
    return [
      {
        label: LABELS.STORE_DELIVERY_DATE_TEXT,
        name: ACTION_LABEL.STORE_DELIVERY_DATE,
        elementType: "createdAt",
        action: handleDate,
        type: "",
        operator: "is from",
      },
    ];
  };

  const columns = useMemo(
    () => [
      {
        header: LABELS.SO_NO_TEXT,
        accessorKey: ACTION_LABEL.SO_NO,
        isShow: true,
      },
      {
        header: LABELS.CUSTOMER_NAME_TEXT,
        accessorKey: ACTION_LABEL.CUSTOMER,
        isShow: true,
      },
      {
        header: LABELS.STATUS_TEXT,
        cell: (row: any) => {
          return row.row.original.status ? (
            <div className="status text-success">{LABELS.CONFIRM_TEXT}</div>
          ) : (
            <div className="status text-danger">{LABELS.PENDING_TEXT}</div>
          );
        },
        isShow: true,
      },
      {
        header: LABELS.DELIVERY_DATE_TEXT,
        accessorKey: ACTION_LABEL.STORE_DELIVERY_DATE,
        cell: ({ row }: any) =>
          secondDateConvert(row.original[ACTION_LABEL.STORE_DELIVERY_DATE]),
        isShow: true,
      },
      {
        header: LABELS.REMAINING_DAY_TEXT,
        cell: (row: any) => {
          const deliveryStDate = row?.row?.original?.deliveryStDate;

          const formattedDeliveryDate = moment(deliveryStDate).startOf("day");

          const currentDate = moment().startOf("day");

          const diffInDays = formattedDeliveryDate.diff(currentDate, "days");

          return diffInDays;
        },
        isShow: true,
      },
    ],
    []
  );

  return (
    <>
      <div className="full-height">
        <Navbar title={LABELS.FOLLOW_UP_TEXT} />
        <main className="main-content">
          <div className="pt-3">
            <div className="container-fluid">
              <div className="row table-top-actions">
                <div className="col-12 col-md-12 d-flex justify-content-end">
                  <div className="d-flex align-items-center row w-100 mx-m-0">
                    {/* <Search
                      placeholder={LABELS.SEARCH_TEXT}
                      name={ACTION_LABEL.SEARCH}
                      value={sort.q}
                      onChange={handleSearchTextChange}
                      onKeyDown={handleKeyDown}
                      onClick={handleSearch}
                    /> */}
                    {/* <SimpleButton
                      className={
                        "me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                      }
                      onClick={handleReportModal}
                      text={BUTTON_LABEL.EXPORT}
                    /> */}

                    <SimpleButton
                      datatoggle="tooltip"
                      dataplacement="top"
                      title={BUTTON_LABEL.IMPORT}
                      className={
                        "me-2 ms-auto btn theme-btn theme-btn-sm btn-inverse w-auto"
                      }
                      onClick={handleRef}
                      text={BUTTON_LABEL.IMPORT}
                    >
                      <input
                        type="file"
                        style={{ display: "none" }}
                        ref={iconInput}
                        onChange={handleCsvImport}
                        accept=".csv"
                        onClick={(e: any) => {
                          e.target.value = null;
                        }}
                        hidden
                      />
                    </SimpleButton>
                    <CSVLink
                      data={csvData}
                      filename={"FollowUpReport.csv"}
                      className="w-auto px-0"
                    >
                      <SimpleButton
                        datatoggle="tooltip"
                        dataplacement="top"
                        title={BUTTON_LABEL.SAMPLE_CSV}
                        className={
                          "me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
                        }
                        text={BUTTON_LABEL.SAMPLE_CSV}
                      />
                    </CSVLink>
                    <SimpleButton
                      className={
                        "me-2 btn theme-btn theme-btn-sm btn-inverse filter-btn"
                      }
                      btnIcon={icons.ICON_FILTER}
                      btnIconClass={"me-2 d-inline-block"}
                      onClick={handleCallFilter}
                    />
                  </div>
                </div>
              </div>
              <div className="card custom-card card-full mt-3">
                <div className="card-body p-0">
                  <Table
                    data={reportFollowUpList}
                    columns={columns}
                    sort={sort}
                    setSort={setSort}
                    coloumnDrop={true}
                    count={count}
                    isFetching={reportListFetching}
                    handleRow={handleReportDetails}
                    isHandleRowEnabled
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <CustomModal
        show={showReportModal}
        close={closeReportModal}
        size={"lg"}
        message={""}
        modalTitle={
          reportDetailData &&
          `${reportDetailData?.customer} | ${reportDetailData?.soNo}`
        }
        modalButton={reportDetailModalButton}
        fullscreen={undefined}
        className={"light-modal inventory-detail-modal report-modal"}
        centerClass={""}
        modalTitleIcon={""}
      >
        <ReportDetail
          reportDetailData={reportDetailData}
          getReportLoading={getReportLoading}
        />
      </CustomModal>

      <Filter
        sort={filterValues}
        filterSelectionError={filterSelectionError}
        filterObject={filterContent(handleDate)}
        show={showFilter}
        onClose={closeFilter}
        handleReset={resetFilter}
        handleApply={handleCallFilter}
        handleOnBlur={handleOnBlur}
      />
    </>
  );

  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => {
  //   setShow(true);
  // };

  // return (
  //   <>
  //     <div className="full-height">
  //       <Navbar title={LABELS.FOLLOW_UP_TEXT} />
  //       {/* <Sidebar /> */}
  //       <main className="main-content">
  //         <div className="pt-3">
  //           <div className="container-fluid">
  //             <div className="row table-top-actions">
  //               <div className="col-12 col-md-4 d-flex align-items-center">
  //                 <a
  //                   href="#"
  //                   className="me-2 btn theme-btn theme-btn-sm btn-inverse"
  //                 >
  //                   <i className="icon icon-filter me-2 d-inline-block"></i>
  //                   {BUTTON_LABEL.FILTER}
  //                 </a>
  //               </div>
  //               <div className="col-12 col-md-8 d-flex justify-content-end">
  //                 <div className="d-flex align-items-center row w-100 mx-m-0">
  //                   <div className="col-12 col-md-7 ms-auto">
  //                     <div className="form-group mb-0 search-input position-relative ">
  //                       <div className="icon">
  //                         <i className="icon icon-search"></i>
  //                       </div>
  //                       <input
  //                         type="text"
  //                         className="form-control bg-white"
  //                         placeholder="Search"
  //                       />
  //                     </div>
  //                   </div>
  //                   <a
  //                     href="#"
  //                     onClick={handleShow}
  //                     className="me-2 btn theme-btn theme-btn-sm btn-inverse w-auto"
  //                   >
  //                     {BUTTON_LABEL.EXPORT}
  //                   </a>
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="card custom-card card-full mt-3">
  //               <div className="card-body p-0">
  //                 <div className="row">
  //                   <div className="col-12">
  //                     <div className="table-responsive">
  //                       <table className="table table-header-shadow mb-0">
  //                         <thead>
  //                           <tr>
  //                             <th>Design No.</th>
  //                             <th>Customer Name </th>
  //                             <th>Status</th>
  //                             <th>Delivery Date</th>
  //                             <th>Remaining Day</th>
  //                             <th>
  //                               <div className="dropdown cell-dropdown"></div>
  //                               <Dropdown className="dropdown cell-dropdown float-end">
  //                                 <Dropdown.Toggle className="no-btn">
  //                                   <a href="#">
  //                                     <svg
  //                                       xmlns="http://www.w3.org/2000/svg"
  //                                       width="18"
  //                                       height="18.015"
  //                                       viewBox="0 0 18 18.015"
  //                                     >
  //                                       <g
  //                                         id="Group_11176"
  //                                         data-name="Group 11176"
  //                                         transform="translate(-1297.437 -289.429)"
  //                                       >
  //                                         <g
  //                                           id="Rectangle_19126"
  //                                           data-name="Rectangle 19126"
  //                                           transform="translate(1297.437 289.437)"
  //                                           fill="#fff"
  //                                           stroke="#707070"
  //                                           stroke-width="1"
  //                                         >
  //                                           <rect
  //                                             width="18"
  //                                             height="18"
  //                                             stroke="none"
  //                                           ></rect>
  //                                           <rect
  //                                             x="0.5"
  //                                             y="0.5"
  //                                             width="17"
  //                                             height="17"
  //                                             fill="none"
  //                                           ></rect>
  //                                         </g>
  //                                         <line
  //                                           id="Line_75"
  //                                           data-name="Line 75"
  //                                           y2="18.015"
  //                                           transform="translate(1303.434 289.429)"
  //                                           fill="none"
  //                                           stroke="#707070"
  //                                           stroke-width="1"
  //                                         ></line>
  //                                         <line
  //                                           id="Line_76"
  //                                           data-name="Line 76"
  //                                           y2="18.015"
  //                                           transform="translate(1309.439 289.429)"
  //                                           fill="none"
  //                                           stroke="#707070"
  //                                           stroke-width="1"
  //                                         ></line>
  //                                       </g>
  //                                     </svg>
  //                                   </a>
  //                                 </Dropdown.Toggle>
  //                                 <Dropdown.Menu className="dropdown-menu has-checkbox">
  //                                   <li>
  //                                     <div className="dropdown-item">
  //                                       <div className="custom-checkbox">
  //                                         <input
  //                                           className="custom-control-input"
  //                                           type="checkbox"
  //                                           id="cat1"
  //                                           value="option1"
  //                                         />
  //                                         <label
  //                                           className="custom-control-label"
  //                                           htmlFor="cat1"
  //                                         >
  //                                           Category 1
  //                                         </label>
  //                                       </div>
  //                                     </div>
  //                                   </li>
  //                                 </Dropdown.Menu>
  //                               </Dropdown>
  //                             </th>
  //                           </tr>
  //                         </thead>
  //                         <tbody>
  //                           <tr>
  //                             <td>KORA006</td>
  //                             <td>Hemanshu Mistry</td>
  //                             <td>
  //                               <div className="status text-success">
  //                                 Confirm
  //                               </div>
  //                             </td>
  //                             <td>23/06/2024</td>
  //                             <td>
  //                               23/06/2024
  //                             </td>
  //                             <td>&nbsp;</td>
  //                           </tr>
  //                           <tr>
  //                             <td>KORA006</td>
  //                             <td>Hemanshu Mistry</td>
  //                             <td>
  //                               <div className="status text-success">
  //                                 Confirm
  //                               </div>
  //                             </td>
  //                             <td>23/06/2024</td>
  //                             <td>
  //                               23/06/2024
  //                             </td>
  //                             <td>&nbsp;</td>
  //                           </tr>
  //                         </tbody>
  //                       </table>
  //                     </div>
  //                   </div>
  //                 </div>
  //                 <div className="row align-items-center pagination">
  //                   <div className="col-12 col-md-4">
  //                     <div className="form-group mb-0">
  //                       Show
  //                       <select
  //                         name=""
  //                         id=""
  //                         className="form-control w-auto d-inline-block"
  //                       >
  //                         <option value="10">10</option>
  //                         <option value="50">50</option>
  //                         <option value="100">100</option>
  //                       </select>
  //                       &nbsp; Entries
  //                     </div>
  //                   </div>
  //                   <div className="col-12 col-md-4">
  //                     <div className="form-group mb-0 text-center">
  //                       Showing 1 to 10 of 223 entries
  //                     </div>
  //                   </div>
  //                   <div className="col-12 col-md-4">
  //                     <nav>
  //                       <ul className="pagination mb-0 justify-content-end">
  //                         <li className="page-item">
  //                           <a className="page-link" href="#">
  //                             &lt;
  //                           </a>
  //                         </li>
  //                         <li className="page-item active">
  //                           <a className="page-link" href="#">
  //                             1
  //                           </a>
  //                         </li>
  //                         <li className="page-item">
  //                           <a className="page-link" href="#">
  //                             2
  //                           </a>
  //                         </li>
  //                         <li className="page-item">
  //                           <a className="page-link" href="#">
  //                             3
  //                           </a>
  //                         </li>
  //                         <li className="page-item">
  //                           <a className="page-link" href="#">
  //                             &gt;
  //                           </a>
  //                         </li>
  //                       </ul>
  //                     </nav>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </main>
  //     </div>
  //     <CustomModal
  //       show={show}
  //       close={handleClose}
  //       size={"lg"}
  //       message={""}
  //       modalTitle={'KORA006 | Hemanshu Mistry'}
  //       modalButton={reportDetailModalButton}
  //       fullscreen={undefined}
  //       className={"light-modal inventory-detail-modal report-modal"}
  //       centerClass={""}
  //       modalTitleIcon={""}
  //     >
  //       <ReportDetail reportDetailData={""} getReportLoading={""} />
  //     </CustomModal>

  //   </>
  // );
};

export default ReportList;
