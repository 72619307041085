import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import {
  ACTION_LABEL,
  BUTTON_LABEL,
  icons,
  LABELS,
  URLS,
} from "../shared/constants";
import TextBox from "../shared/components/TextBox";

const ResetPassword = (props: any) => {
  const { resetData, handleOnChange, handleOnBlur, handleGetOtp, error } =
    props;

  return (
    <>
      <Form>
        {/* <Form.Group className="form-group form-group-icon">
          <i className={`icon  ${icons.ICON_MAIL}`}></i>
          <Form.Label>{LABELS.EMAIL_ADDRESS_TEXT}</Form.Label>
          <Form.Control
            type="userName"
            placeholder={LABELS.EMAIL_ADDRESS_TEXT}
            inputMode="text"
            id={ACTION_LABEL.USERNAME}
            name={ACTION_LABEL.USERNAME}
            autoFocus
            value={resetData.username}
            onChange={handleOnChange}
          />
        </Form.Group> */}
        <div className="form-group form-group-icon">
          <TextBox
            name={ACTION_LABEL.USERNAME}
            placeholder={LABELS.USERNAME_TEXT}
            id={LABELS.USERNAME_TEXT}
            label={LABELS.USERNAME_TEXT}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            value={resetData.username}
            icon={icons.ICON_MAIL}
            errorMessage={error.username || null}
          />
        </div>
        <div className="form-group my-3">
          {LABELS.ALREADY_HAVE_AN_ACOUNT_TEXT}
          <Link to={URLS.LOGIN}>{LABELS.LOGIN_TEXT}</Link>
        </div>
        <div className="text-center mt-5">
          <Link
            to="#"
            className="btn theme-btn text-uppercase"
            onClick={handleGetOtp}
          >
            {BUTTON_LABEL.SEND_OTP}
          </Link>
        </div>
      </Form>
    </>
  );
};

export default ResetPassword;
