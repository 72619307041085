const TextBox = (props: any) => {
  const {
    placeholder,
    required,
    id,
    value,
    name,
    onChange,
    label,
    errorMessage,
    disable,
    className,
    onClick,
    onKeyDown,
    onDoubleClick,
    onFocus,
    hidden,
    maxLength,
    onBlur,
    color,
    icon,
  } = props;
  return (
    <>
      {icon && <i className={`icon ${icon}`}></i>}
      {label && <label htmlFor={id}>{label}</label>}
      {required && <span className="text-error">*</span>}
      <input
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        onKeyDown={onKeyDown}
        type={hidden ? "password" : "text"}
        className={className || "form-control"}
        placeholder={placeholder}
        name={name}
        disabled={disable || undefined}
        onFocus={onFocus}
        onBlur={onBlur}
        maxLength={maxLength || 255}
        style={{ color: color }}
        autoComplete="false"
        onChange={onChange}
        value={value}
      />
      <span className="text-error">{errorMessage}</span>
    </>
  );
};

export default TextBox;
