export const convertToAppropriateType = (value: any, type: string) => {
  if (type === "boolean") {
    return value === "true";
  } else if (type === "number") {
    const numberValue = Number(value);
    if (!isNaN(numberValue)) {
      return numberValue;
    }
  } else {
    return value;
  }
};
